import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import Input from "../parents/Input.js";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Dropdown from "../parents/Dropdown.js";
import useCachedToken from '../../../useCachedToken.js';
import { toast } from 'react-hot-toast';
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const defaultStyles = {
  Title: {
      color: '#030303',
      fontSize: '1.3rem',
      fontWeight: 'bold',
      lineHeight: '24px',
  },
  AcceptButton: {
      padding: '1.5rem 3rem',
      border: '0',
      boxSizing: 'border-box',
      borderRadius: '6px',
      boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
      backgroundColor: '#82e8ed',
      color: '#000000',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '18px',
      outline: 'none',
      cursor: 'pointer',
  },
  DisabledAcceptButton: {
      padding: '1.5rem 3rem',
      border: '0',
      boxSizing: 'border-box',
      borderRadius: '6px',
      boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
      backgroundColor: '#4ec5ca',
      color: '#000000',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '18px',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'not-allowed',
  },
  CancelButton: {
      padding: '1.5rem 3rem',
      border: '0',
      boxSizing: 'border-box',
      borderRadius: '6px',
      boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
      backgroundColor: '#f6f6f6',
      color: '#000000',
      fontSize: '1rem',
      lineHeight: '18px',
      outline: 'none',
      cursor: 'pointer',
  },
  SpinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0',
  },
  ErrorText: {
      color: 'red',
      listStyleType: 'disc',
      marginLeft: '20px',
      marginBottom: '10px',
  },
  FormContainer: {
      opacity: 1,
      transition: 'opacity 0.3s ease',
  },
  FormContainerLoading: {
      opacity: 0.5,
      transition: 'opacity 0.3s ease',
  },
};

const DomainsModalAdd = ({ reload }) => {
  const { relay_server_id, project_id } = useParams(); // Extract IDs from the URL
  const [name, setName] = useState("");
  const [selectedIp, setSelectedIp] = useState("");
  const [ips, setIps] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [isFetching, setIsFetching] = useState(false); // State for data fetching
  const [isSubmitting, setIsSubmitting] = useState(false); // State for form submission
  const [errors, setErrors] = useState([]);
  const { getToken } = useAuth();
  const token = useCachedToken();

  const fetchData = async () => {
    setIsFetching(true);
    setErrors([]);
    const token = await getToken();
    if (!token) {
      setIsFetching(false);
      return;
    }

    try {
      // Fetch IPs
      const ipResponse = await fetch(`${DJANGO_HOST}user_relay_server/`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${token}`,
        },
      });
      const ipData = await ipResponse.json();
      if (!ipResponse.ok) {
        throw new Error(`Failed to fetch IPs. Status: ${ipResponse.status}`);
      }

      // Log the IP data for debugging
      console.log('ipData:', ipData);

      const isIpDataAnObject = typeof ipData === 'object' && ipData !== null && !Array.isArray(ipData);
      const ipDataResults = isIpDataAnObject ? ipData.results : ipData;
      setIps(ipDataResults || []);

      // Set selected IP if relay_server_id is provided
      if (relay_server_id) {
        const selectedIpData = (ipDataResults || []).find(ip => ip.relay_server_id.toString() === relay_server_id.toString());
        if (selectedIpData) {
          setSelectedIp(selectedIpData.relay_server_id); // Set the ID directly
        } else {
          setSelectedIp("");
          setErrors(prev => [...prev, "Selected IP not found."]);
        }
      }

      // Fetch Projects
      const projectResponse = await fetch(`${DJANGO_HOST}projects/`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!projectResponse.ok) {
        throw new Error(`Failed to fetch Projects. Status: ${projectResponse.status}`);
      }
      const projectData = await projectResponse.json();

      // Log the projectData for debugging
      console.log('projectData:', projectData);

      // Ensure projects is always an array
      const isProjectDataAnObject = typeof projectData === 'object' && projectData !== null && !Array.isArray(projectData);
      const projectDataResults = isProjectDataAnObject ? projectData.results : projectData;
      setProjects(projectDataResults || []);

      // Set selected Project if project_id is provided
      if (project_id) {
        const selectedProjectData = (projectDataResults || []).find(project => project.id.toString() === project_id.toString());
        if (selectedProjectData) {
          setSelectedProject(selectedProjectData.id); // Set the ID directly
        } else {
          setSelectedProject("");
          setErrors(prev => [...prev, "Selected Project not found."]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrors([error.message || 'An unexpected error occurred while fetching data.']);
    } finally {
      setIsFetching(false);
    }
  };

  // Fetch IPs and Projects on component mount
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken, relay_server_id, project_id]);

  const handleIpChange = (e) => {
    setSelectedIp(e.target.value);
  };

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);
  };

  const renderTitle = () => {
    return (
      <label style={defaultStyles.Title}>Add Domain</label>
    );
  };

  const renderBody = () => {
    return (
      <>
        {isFetching ? (
          <div style={defaultStyles.SpinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <>
            <p className="text-justify">
              Add your domains to Mission Inbox. This way, you can benefit from our high-volume mailboxes with your mature domains.
            </p>
            <div className="pb-4">
              <label className="font-semibold">IP (Relay Server)</label>
              <select
                value={selectedIp}
                onChange={handleIpChange}
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                disabled={!!relay_server_id}
              >
                <option value="" disabled hidden>
                  Select an IP
                </option>
                {Array.isArray(ips) && ips.map((ip, index) => (
                  <option key={index} value={ip.relay_server_id}>
                    {ip.relay_server_name} {/* Display relay server name */}
                  </option>
                ))}
              </select>
            </div>

            <div className="pb-4">
              <label className="font-semibold">Project</label>
              <select
                value={selectedProject}
                onChange={handleProjectChange}
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                disabled={!!project_id}
              >
                <option value="" disabled hidden>
                  Select a Project
                </option>
                {Array.isArray(projects) && projects.map((project, index) => (
                  <option key={index} value={project.id}>
                    {project.name} {/* Display project name */}
                  </option>
                ))}
              </select>
            </div>

            <div className="pb-4">
              <label className="font-semibold">Domain</label>
              <Input
                name="name"
                placeholder="Domain Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const renderOptions = (closeModal) => {
    const action = async () => {

      // Before trying to do any action, verify the inputs
      const newErrors = [];

      const domainRegex = /^[a-zA-Z0-9-.]+$/;
      if (!selectedIp) {
        newErrors.push("Please select an IP.");
      }
      if (!selectedProject) {
        newErrors.push("Please select a Project.");
      }
      if (!name.trim()) {
        newErrors.push("Domain cannot be empty.");
      } else if (!domainRegex.test(name.trim())) {
        newErrors.push("Domain can only contain letters, numbers, hyphens (-), and periods (.)");
      }

      if (newErrors.length > 0) {
        setErrors(newErrors);
        return;
      }

      // In case no errors are found, clean the previous ones
      setErrors([]);
      setIsSubmitting(true);

      const url = `${DJANGO_HOST}domain/`;

      try {
        const token = await getToken();
        if (!token) {
          throw new Error("Authentication token is missing. Please log in again.");
        }

        const payload = {
          'name': name.trim(),
          'relay_server': selectedIp,
          'project': selectedProject
        };

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: JSON.stringify(payload),
        });

        if (response.status === 201) {
          toast.success('Domain added successfully!');
          closeModal();
          reload(); // Call the reload function after successful addition
        } else if (response.status === 400) {
          const data = await response.json();
          if (data.detail) {
            toast.error(data.detail);
          } else {
            toast.error('Cannot add this domain. It may already exist in our system.');
          }
        } else {
          toast.error('An error occurred while adding the domain.');
          console.error('Error in response:', await response.json());
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error(error.message || 'An unexpected error occurred. Please try again later.');
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <>
        <Button
          action={closeModal}
          style={defaultStyles.CancelButton}
          label="Cancel"
          disabled={isSubmitting}
          aria-label="Cancel adding domain"
        />
        <Button
          action={action}
          style={isSubmitting ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton}
          label={isSubmitting ? "Processing..." : "Add Domain"}
          disabled={isSubmitting}
          aria-label={isSubmitting ? "Processing domain addition" : "Confirm adding domain"}
        />
      </>
    );
  };

  const renderErrors = () => {
    if (errors.length === 0) return null;
    return (
      <div style={defaultStyles.ErrorText}>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>
              - {error}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <Modal
      text={"Add Domain"}
      renderTitle={renderTitle}
      renderBody={renderBody}
      renderOptions={renderOptions}
      renderErrors={renderErrors}
    />
  );
};

export default DomainsModalAdd;
