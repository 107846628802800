import React from 'react';

const Dropdown = ({ value, onChange, label, values, style, disabled }) => {
  const mergedStyle = {
    ...style,
    // Remove appearance: none to keep the default browser arrow
    paddingRight: '30px', // Ensure padding for default arrow
  };

  return (
    <div className="relative">
      <select
        className="block w-full border-gray-400 hover:border-gray-500 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        value={value}
        onChange={onChange}
        style={mergedStyle}
        disabled={disabled}
      >
        <option value="" disabled hidden>
          {label}
        </option>
        {Object.entries(values).map(([key, val]) => (
          <option key={key} value={val}>
            {key}
          </option>
        ))}
      </select>
      {/* Custom SVG arrow removed */}
    </div>
  );
};

export default Dropdown;
