import React, { useEffect, useState } from 'react';
import Table from '../parents/Table';
import { Link } from 'react-router-dom';
import useCachedToken from '../../../useCachedToken'; // Import the custom hook for token
import config from '../../../config/env';  // Import the config
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const ControlCenterDomainsTable = ({ reloadKey }) => {
  // Use the custom hook to get the cached token
  const token = useCachedToken();

  // API URL: Fetch all domains sorted from latest to oldest
  const baseUrl = `${DJANGO_HOST}domain/?sort_by=created_at&sort_order=desc`;

  const headers = [
    { name: "Domain", available: true },
    { name: "Mailboxes", available: true },
    { name: "Sent Emails", available: true },
    { name: "Status", available: true }
  ];

  // State variables for managing domain data and pagination
  const [domains, setDomains] = useState([]); // Domains to display on the current page
  const [totalItems, setTotalItems] = useState(0); // Total number of domains
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const itemsPerPage = 4; // Fixed at 4 items per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch domains from the API
  const fetchDomains = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const limit = itemsPerPage;
      const offset = (currentPage - 1) * itemsPerPage;
      const fetchUrl = `${baseUrl}&limit=${limit}&offset=${offset}`;

      const response = await fetch(fetchUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}` // Include auth token
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setTotalItems(data.count || 0);
      setDomains(Array.isArray(data.results) ? data.results : []);
    } catch (error) {
      console.error('Error fetching domains:', error);
      setError('Failed to load domains. Please try again later.');
      setDomains([]); // Reset to empty array on error
      setTotalItems(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch domains when component mounts or when token, currentPage, or itemsPerPage changes
  useEffect(() => {
    if (token) {
      fetchDomains();
    }
  }, [token, currentPage, itemsPerPage]);

  // Calculate totalPages whenever totalItems or itemsPerPage changes
  useEffect(() => {
    const total = Math.ceil(totalItems / itemsPerPage) || 1;
    setTotalPages(total || 1); // Ensure at least 1 page
  }, [totalItems, itemsPerPage]);

  // Ensure currentPage is within totalPages
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  // Pagination Helper Function to generate page numbers with ellipses
  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 2) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage > 2) {
        pages.push('left-ellipsis');
      }
      const startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, currentPage + 1);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (currentPage < totalPages - 1) {
        pages.push('right-ellipsis');
      }
    }
    return pages;
  };

  // Handle page change when a page number is clicked
  const handlePageChange = (pageNumber) => {
    if (pageNumber === 'left-ellipsis') {
      setCurrentPage(1); // Jump to first page
      return;
    }
    if (pageNumber === 'right-ellipsis') {
      setCurrentPage(totalPages); // Jump to last page
      return;
    }
    setCurrentPage(pageNumber);
  };

  // Style Objects for consistent styling
  const styles = {
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
      gap: '5px',
    },
    paginationButton: (isActive, isDisabled) => ({
      padding: '8px 12px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: isActive ? '#17a2b8' : 'white',
      color: isActive ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      opacity: isDisabled ? 0.6 : 1,
    }),
    paginationEllipsis: {
      padding: '8px 12px',
    },
    seeMoreLink: {
      textDecoration: 'none',
      color: '#17a2b8',
      fontWeight: 'bold',
      padding: '10px',
    },
    errorText: {
      color: 'red',
      textAlign: 'center',
      marginTop: '10px',
    },
    noResultsText: {
      textAlign: 'center',
      marginTop: '20px',
      color: '#555',
    },
  };

  // Custom renderers for specific columns
  const renderDomainLink = (cell, row) => {
    const domainId = row.id;
    return (
      <td className="py-3 px-6 whitespace-normal break-words">
        <Link to={`/navigate/domain/${domainId}/`}>
          {cell}
        </Link>
      </td>
    );
  };

  const renderStatus = (cell, row) => {
    const status = row.status || cell;
    return (
      <td className="py-3 px-6">
        {status === 'Verified' ? 
          <span className="text-green-500">Verified</span> : 
          <span className="text-red-500">Not Verified</span>
        }
      </td>
    );
  };

  const renderSuffix = (row) => {
    // Implement if you need to render any suffix column
    return null;
  };

  return (
    <div>
      {/* Display loading spinner */}
      {isLoading && <Spinner />}

      {/* Display error message if any */}
      {error && <div style={styles.errorText}>{error}</div>}

      {/* Display "No results found" if not loading, no error, and no domains */}
      {!isLoading && !error && domains.length === 0 && (
        <div style={styles.noResultsText}>No domains found.</div>
      )}

      {/* Table Component */}
      {!isLoading && !error && domains.length > 0 && (
        <>
          <Table
            data={domains} // Pass the current page's domains
            title={"Domains"}
            headers={headers}
            preffixColumn={false}
            renderPrefix={null}
            renderSuffix={renderSuffix}
            customRenderers={{ 
              "Domain": renderDomainLink,  // Render domain as a clickable link
              "Status": renderStatus
            }}
            excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints', 'health', 'project_id', 'relay_server_id']}  // Exclude unwanted fields
          />

      {/* Pagination Controls */}
      {!isLoading && !error && totalPages > 1 && (
        <div style={styles.paginationContainer}>
          {/* Previous Button */}
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={styles.paginationButton(false, currentPage === 1)}
            aria-label="Previous Page"
          >
            &laquo;
          </button>

          {/* Page Numbers and Ellipses */}
          {getPageNumbers().map((item, index) => {
            if (item === 'left-ellipsis') {
              return (
                <button
                  key={index}
                  onClick={() => handlePageChange(1)}
                  style={styles.paginationButton(false, false)}
                  aria-label="First Page"
                >
                  1
                </button>
              );
            }
            if (item === 'right-ellipsis') {
              return (
                <button
                  key={index}
                  onClick={() => handlePageChange(totalPages)}
                  style={styles.paginationButton(false, false)}
                  aria-label="Last Page"
                >
                  {totalPages}
                </button>
              );
            }

                return (
                  <button
                    key={index}
                    onClick={() => handlePageChange(item)}
                    style={styles.paginationButton(currentPage === item, false)}
                    aria-current={currentPage === item ? 'page' : undefined}
                    aria-label={`Page ${item}`}
                  >
                    {item}
                  </button>
                );
              })}

              {/* Next Button */}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={styles.paginationButton(false, currentPage === totalPages)}
                aria-label="Next Page"
              >
                &raquo;
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ControlCenterDomainsTable;
