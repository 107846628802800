import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate(); // useNavigate instead of useHistory

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // Redirect to search results page with the search query
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div className="relative w-full max-w-md group">
      <input
        type="text"
        placeholder="Search"
        className="w-full bg-white rounded-md border px-4 py-2 text-sm text-black focus:outline-none focus:ring-2 focus:ring-blue-500 group-hover:bg-gray-300"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        {/* Your SVG Icon */}
      </div>
    </div>
  );
};

export default SearchBar;
