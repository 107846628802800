import React, { useState } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import { useAuth } from '@clerk/clerk-react';
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
    ErrorText: {
        color: 'red',
        listStyleType: 'disc',
        marginLeft: '20px',
        marginBottom: '10px',
    },
};

const DJANGO_HOST = config.djangoHost;

const MailboxesModalDelete = ({ id, name, reload }) => {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getToken } = useAuth();

    // Function to handle deleting the mailbox
    const handleDelete = async (closeModal) => {
        const url = `${DJANGO_HOST}mailbox/${id}/`;

        setLoading(true);
        setErrors([]);

        try {
            const token = await getToken();

            console.log("Deleting mailbox with id:", id);

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });

            if (response.status === 204) {
                console.log("Mailbox deleted successfully.");
                closeModal();
                reload();
            } else if (response.headers.get('content-type')?.includes('application/json')) {
                const errorData = await response.json();
                setErrors([errorData.message || "Failed to delete mailbox"]);
            } else {
                setErrors(["Failed to delete mailbox. Unexpected response."]);
            }
        } catch (error) {
            console.error('Error deleting mailbox:', error);
            setErrors([error.message || "An error occurred while deleting mailbox."]);
        } finally {
            setLoading(false);
        }
    };

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Are you sure you want to delete - {name}?</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                {loading ? (
                    <div style={defaultStyles.SpinnerContainer}>
                        <Spinner />
                    </div>
                ) : (
                    <p className="text-justify">
                        By deleting this mailbox, you will not be able to send emails through it anymore.
                    </p>
                )}
            </>
        );
    };

    const renderOptions = (closeModal) => {
        return (
            <>
                <Button
                    action={closeModal}
                    style={defaultStyles.CancelButton}
                    label="Close"
                    disabled={loading}
                    aria-label="Cancel deleting mailbox"
                />
                <Button
                    action={() => handleDelete(closeModal)}
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton}
                    label={loading ? "Processing..." : "Yes, Delete it"}
                    disabled={loading}
                    aria-label={loading ? "Processing mailbox deletion" : "Confirm mailbox deletion"}
                />
            </>
        );
    };

    const renderErrors = () => {
        if (errors.length === 0) return null;
        return (
            <ul style={defaultStyles.ErrorText}>
                {errors.map((error, index) => (
                    <li key={index}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            icon={"delete"}
            tooltip={"Delete"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalDelete;
