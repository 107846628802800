// Mailboxes.js

import React, { useState, useEffect, useCallback } from 'react';
import MailboxesTable from "./components/Mailboxes/MailboxesTable.js";
import MailboxesModalAdd from './components/Mailboxes/MailboxesModalAdd.js';
import Dropdown from './components/parents/Dropdown.js';
import { useAuth } from '@clerk/clerk-react';
import config from '../config/env';
import { useParams } from 'react-router-dom'; // Ensure you have useParams imported

const Mailboxes = () => {
  const [reloadKey, setReloadKey] = useState(0);
  const DJANGO_HOST = config.djangoHost;
  const { getToken } = useAuth();
  const { domain_id } = useParams(); // Extract domain_id from URL parameters

  const handleDropdownChange = async (event) => {
    const value = event.target.value;
    let id = 'all'; // Default to 'all' if domain_id is not present

    if (domain_id) {
      id = domain_id; // Use domain_id if available
    }

    let url = '';

    if (value === 'instantly') {
      url = `${DJANGO_HOST}mailbox/download/instantly/${id}/`;
    } else if (value === 'smartlead') {
      url = `${DJANGO_HOST}mailbox/download/smartlead/${id}/`;
    } else if (value === 'reply.io' || value === 'replyio') {
      url = `${DJANGO_HOST}mailbox/download/reply.io/${id}/`;
    }

    try {
      const token = await getToken();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Requested-With': 'XMLHttpRequest',
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = value === 'instantly' ? 'instantly_export.csv' :
                    value === 'smartlead' ? 'smartlead_export.csv' :
                    'replyio_export.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        console.error('Error downloading file:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const dropdownValues = {
    'Instantly': 'instantly',
    'SmartLead': 'smartlead',
    'Reply.io': 'reply.io', // Add Reply.io option
  };

  const triggerReload = useCallback(() => {
    setReloadKey((prevKey) => prevKey + 1);
  }, []);

  const dropdownStyle = {
    padding: '8px 12px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#17a2b8',  // Greenish background
    color: '#ffffff', // White text color
    fontSize: '1rem',
    fontWeight: 500,
    cursor: 'pointer',
    appearance: 'none', // Remove default arrow
  };

  return (
    <div>
      <nav className="navbar flex justify-between items-center p-4">
        <label className='page-header text-lg font-bold'>Mailboxes</label>
        <div className="relative inline-block w-32">
          <Dropdown
            value=""
            onChange={handleDropdownChange}
            label="Bulk Export"
            values={dropdownValues}
            style={dropdownStyle} // Apply greenish background color here
          />
        </div>
      </nav>
      <div className="flex w-full justify-end p-4">
        <MailboxesModalAdd reload={triggerReload}/>
      </div>
      <div className="px-10 py-5">
        <MailboxesTable reload={triggerReload} key={reloadKey} /> {/* Use reloadKey to force re-render if needed */}
      </div>
    </div>
  );
}

export default Mailboxes;
