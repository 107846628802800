import React, { useEffect, useState } from 'react';
import Button from './Button.js';
import Icon from './Icon.js';
import Switch from './Switch.js';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { v4 as uuidv4 } from 'uuid';

const defaultTitle = () => {
    return (
        <label style={defaultStyles.Title}>
            Failed rendering this Modal Title
        </label>
    );
};

const defaultBody = () => {
    return (
        <p style={defaultStyles.Body}>
            Failed rendering this Modal Body
        </p>
    );
};

const defaultOptions = (closeModal) => {
    return (
        <label style={defaultStyles.Options}>
            Failed rendering this Modal Options
        </label>
    );
};

const defaultErrors = () => {
    return (
        <label style={defaultStyles.Errors}>
            Failed rendering this Modal Errors (how ironic)
        </label>
    );
}

const defaultStyles = {
    Modal : {
        borderRadius: '16px',
        border: '1px solid #0000ff',
    },
    Title : {
        color: 'red'
    },
    Body : {
        color: 'red'
    },
    Options : {
        color: 'red'
    },
    Errors : {
        color : 'red'
    }
}

const Modal = ({ text, icon, toggle, isChecked, tooltip, styles=defaultStyles, maxWidth="max-w-lg", renderTitle=defaultTitle, renderBody=defaultBody, renderOptions=defaultOptions, renderErrors=defaultErrors, onClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [uniqueId] = useState("id-" + uuidv4());
    const openModal = () => setIsOpen(true);
    
    const closeModal = () => {
        setIsOpen(false);
        if (onClose && typeof onClose === 'function') {
            onClose(); // Invoke the onClose callback when modal is closed
        }
    };

    useEffect(() => {
        if(tooltip){
            tippy(`#${uniqueId}`, {
                content: tooltip,
                theme: 'light',
              });
        }
      }, [tooltip, uniqueId]);

    return (
        <>
            <div id={uniqueId}>
                {
                    text ? <Button action={openModal} label={text}></Button> :
                    icon ? <Icon type={icon} action={openModal}></Icon> :
                    toggle ? <Switch trueAction={null} falseAction={openModal} isChecked={isChecked}></Switch> :
                    <label onClick={openModal}>Open Modal</label>
                }
            </div>
            {
                isOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
                        <div className={`bg-white rounded-lg p-8 z-10 mx-auto ${maxWidth}`} style={styles.Modal}>
                            <div className='flex w-full pb-4'>
                                <div className="flex mb-4 mt-4 w-11/12">
                                    {renderTitle()}
                                </div>
                                <button
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    onClick={closeModal} // Ensure closeModal is called when 'X' is clicked
                                    >
                                        <svg
                                        className="h-6 w-6 fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 15 15"
                                        >
                                            <path
                                            fillRule="evenodd"
                                            d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                            />
                                        </svg>
                                </button>
                            </div>
                            <div className='pb-4'>
                                {renderBody()}
                            </div>
                            <div className='pb-4'>
                                {renderErrors()}
                            </div>
                            <div className='flex justify-around'>
                                {/* renderOptions must include the closeModal options for the cancel button*/}
                                {renderOptions(closeModal)}
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default Modal;
