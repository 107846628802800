import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import Table from '../parents/Table';
import config from '../../../config/env';  // Import the config
import useCachedToken from '../../../useCachedToken'; // Import the custom hook

const DJANGO_HOST = config.djangoHost;

const DomainsModalDNSTable = ({id}) => {
    const [domainData, setDomainData] = useState(null);
    const [error, setError] = useState(null);
    const token = useCachedToken();

    const url = `${DJANGO_HOST}domain/${id}/?records=true`;

    const headers = [
      { name: "Host Name", available: true },
      { name: "Record Type", available: true },
      { name: "Value", available: true },
      { name: "Status", available: true }
    ];
  
    const handlePrefixAction = (option, id) => {};

    const handleSuffixAction = (option, id) => {};

    const renderPrefix = (row) => {
      return (
        <td className="py-3 pl-6 text-left whitespace-nowrap">
          Lock check
        </td>
      );
    };

    const renderSuffix = (row) => {
        const suffixConfig = ['edit'];
    };

    useEffect(() => {
        const fetchDomainData = async () => {
            if (!token) return; // Don't fetch if we don't have a token

            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch domain data');
                }

                const data = await response.json();
                setDomainData(data);
                setError(null);
            } catch (err) {
                console.error('Error fetching domain data:', err);
                setError(err.message);
            }
        };

        fetchDomainData();
    }, [id, token]); // Re-run if id or token changes

    return (
      <Table
        data={domainData} // Assuming the API returns a 'records' array
        headers={headers}
        preffixColumn={false}
        suffixColumn={false}
        renderPrefix={renderPrefix}
        renderSuffix={renderSuffix}
        handlePrefixAction={handlePrefixAction}
        handleSuffixAction={handleSuffixAction}
      />
    );
  };
  
  export default DomainsModalDNSTable;