import React, { useState } from "react";
import { Link } from 'react-router-dom'; // Correctly import Link from react-router-dom
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    ErrorText: {
        color: 'red',
        listStyleType: 'disc',
        marginLeft: '20px',
        marginBottom: '10px',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
};

const DJANGO_HOST = config.djangoHost;

const ConnectedIpsModalDelete = ({ name, id, reload }) => {
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getToken } = useAuth();

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Are you sure you want to delete - {name}?</label>
        );
    };

    const renderBody = () => {
        // If loading, show spinner
        if (isLoading) {
            return (
                <div style={defaultStyles.SpinnerContainer}>
                    <Spinner />
                </div>
            );
        }

        // Else, show the confirmation message
        return (
            <>
                <p className="text-justify">
                    By deleting this IP, all related projects, domains, and mailboxes will be deleted, and no emails will be sent from them.
                </p>
            </>
        );
    };

    const renderOptions = (closeModal) => {
        const action = async () => {
            setErrors([]); // Reset errors
            setIsLoading(true); // Start loading

            const url = `${DJANGO_HOST}relay_servers/${id}/`;

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                });

                if (response.status === 204) {
                    reload(); // Refresh data
                    closeModal(); // Close the modal
                } else if (response.status === 400) {
                    // Handle validation errors returned by the server
                    const data = await response.json();
                    const serverErrors = [];
                    for (const key in data) {
                        if (Array.isArray(data[key])) {
                            data[key].forEach(err => serverErrors.push(`${key}: ${err}`));
                        } else {
                            serverErrors.push(`${key}: ${data[key]}`);
                        }
                    }
                    setErrors(serverErrors);
                } else {
                    // Handle other types of errors
                    const errorText = await response.text();
                    setErrors(['An unexpected error occurred. Please try again later.']);
                    console.error('Unexpected response:', response.status, errorText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrors(['Failed to delete the IP. Please check your network and try again.']);
            }

            setIsLoading(false); // Stop loading
        };

        return (
            <>
                <Button
                    action={closeModal}
                    style={defaultStyles.CancelButton}
                    label="Cancel"
                    disabled={isLoading} // Disable during loading
                />
                <Button
                    action={action}
                    style={isLoading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton}
                    label={isLoading ? "Processing..." : "Yes, Delete it"}
                    disabled={isLoading}
                />
            </>
        );
    };

    const renderErrors = () => {
        if (errors.length === 0) return null;
        return (
            <ul className="mb-4">
                {errors.map((error, index) => (
                    <li key={index} style={defaultStyles.ErrorText}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            icon={"delete"}
            tooltip={"Delete"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default ConnectedIpsModalDelete;
