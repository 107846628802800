// src/pages/components/SearchResults.js

import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import useCachedToken from '../../useCachedToken';
import config from '../../config/env';
import Table from './parents/Table.js';
import Spinner from './Spinner'; // Ensure Spinner is imported

// Import your existing modal components
import ConnectedIpsModalEdit from './ConnectedIPs/ConnectedIpsModalEdit.js';
import ConnectedIpsModalDelete from './ConnectedIPs/ConnectedIpsModalDelete';
import ProjectsModalEdit from './Projects/ProjectsModalEdit';
import ProjectsModalDelete from './Projects/ProjectsModalDelete';
import DomainsModalDNS from './Domains/DomainsModalDNS';
import DomainsModalDelete from './Domains/DomainsModalDelete';
import MailboxesModalConnect from './Mailboxes/MailboxesModalConnect';
import MailboxesModalEdit from './Mailboxes/MailboxesModalEdit';
import MailboxesModalDelete from './Mailboxes/MailboxesModalDelete';

// Import the DomainVerificationProvider
import { DomainVerificationProvider } from './Domains/DomainsVerificationContext';

const SearchResults = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query') || '';
    const token = useCachedToken();
    const DJANGO_HOST = config.djangoHost;
  
    // State variables for each entity
    const [ipResults, setIpResults] = useState([]);
    const [projectResults, setProjectResults] = useState([]);
    const [domainResults, setDomainResults] = useState([]);
    const [mailboxResults, setMailboxResults] = useState([]);
    
    // Loading state
    const [loading, setLoading] = useState(false);
  
    // Reload State
    const [reloadFlag, setReloadFlag] = useState(0);
    const reload = () => setReloadFlag(prev => prev + 1);
  
    useEffect(() => {
      // Function to fetch data from API
      const fetchData = async (endpoint, setState) => {
        try {
          const response = await fetch(`${DJANGO_HOST}${endpoint}?search=${encodeURIComponent(query)}`, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
          setState(Array.isArray(data) ? data : []);
        } catch (error) {
          console.error(`Error fetching ${endpoint} search results:`, error);
          setState([]);
        }
      };
  
      // Only fetch data if token and query are present
      if (token && query) {
        setLoading(true); // Start loading
        // Create an array of fetch promises
        const fetchPromises = [
          fetchData('user_relay_server/', setIpResults),
          fetchData('projects/', setProjectResults),
          fetchData('domain/', setDomainResults),
          fetchData('mailbox/', setMailboxResults)
        ];
  
        // Wait for all fetches to complete
        Promise.all(fetchPromises)
          .then(() => setLoading(false)) // Stop loading when all fetches are done
          .catch(() => setLoading(false)); // Also stop loading if any fetch fails
      } else {
        // If there's no token or query, reset results and stop loading
        setIpResults([]);
        setProjectResults([]);
        setDomainResults([]);
        setMailboxResults([]);
        setLoading(false);
      }
    }, [token, query, DJANGO_HOST, reloadFlag]);
  
    // Function to highlight matching text
    const highlightMatch = (text, query) => {
      if (!query) return text;
      const regex = new RegExp(`(${query})`, 'gi');
      const parts = text.split(regex);
      return (
        <>
          {parts.map((part, index) =>
            regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
          )}
        </>
      );
    };
  
    // Render functions for each entity
  
    // IPs
    const renderIP = (cell, row) => {
      const id = row.relay_server_id;
      return (
        <td className="py-3 px-6">
          <Link to={`/navigate/ip/${id}/`}>
            {highlightMatch(cell, query)}
          </Link>
        </td>
      );
    };
  
    // Projects
    const renderProject = (cell, row) => {
      const id = row.id;
      return (
        <td className="py-3 px-6">
          <Link to={`/navigate/project/${id}/`}>
            {highlightMatch(cell, query)}
          </Link>
        </td>
      );
    };
  
    // Domains
    const renderDomain = (cell, row) => {
      const id = row.id;
      return (
        <td className="py-3 px-6">
          <Link to={`/navigate/domain/${id}/`}>
            {highlightMatch(cell, query)}
          </Link>
        </td>
      );
    };
  
    // Mailboxes
    const renderMailbox = (cell, row) => {
      const id = row.id;
      return (
        <td className="py-3 px-6">
          <Link to={`/navigate/mailbox/${id}/`}>
            {highlightMatch(cell, query)}
          </Link>
        </td>
      );
    };

    // Function to render the status cell
    const renderStatus = (cell, row) => {
      const status = cell;
      return (
        <td className="py-3 px-6">
          {status === true || status === 'Verified' ? 
          <span className="text-green-500">Verified</span> : 
          <span className="text-red-500">Not Verified</span>
          }
        </td>
      );
    };
  
    // Define table headers for each entity
    const ipHeaders = [
      { name: "IPS", available: true, key: "relay_server_name" },
      { name: "Provider", available: true, key: "relay_server_provider" },
      { name: "Projects", available: true, key: "projects" },
      { name: "Domains", available: true, key: "domains" },
      { name: "Mailboxes", available: true, key: "mailboxes" },
      { name: "Sent Emails", available: true, key: "sent_emails" }
    ];
  
    const projectHeaders = [
      { name: "Project", available: true, key: "name" },
      { name: "Domains", available: true, key: "domains" },
      { name: "Mailboxes", available: true, key: "mailboxes" },
      { name: "Sent Emails", available: true, key: "sent_emails" }
    ];
  
    const domainHeaders = [
      { name: "Domain", available: true, key: "name" },
      { name: "Mailboxes", available: true, key: "mailboxes" },
      { name: "Sent Emails", available: true, key: "sent_emails" },
      { name: "Status", available: true, key: "status" }
    ];
  
    const mailboxHeaders = [
      { name: "Mailbox", available: true, key: "email" },
      { name: "First Name", available: true, key: "first_name" },
      { name: "Last Name", available: true, key: "last_name" },
      { name: "Sent Emails", available: true, key: "sent_this_cycle" },
      { name: "Domain", available: true, key: "domain_name" },
      { name: "Reply-To", available: true, key: "reply_to" }
    ];
  
    // Define renderSuffix functions inline
  
    // IPs Actions
    const renderIpActions = (row) => (
      <td className="py-3 px-6 flex space-x-2">
        <ConnectedIpsModalEdit name={row.relay_server_name} id={row.relay_server_id} reload={reload} />
        <ConnectedIpsModalDelete name={row.relay_server_name} id={row.relay_server_id} reload={reload} />
      </td>
    );
  
    // Projects Actions
    const renderProjectActions = (row) => (
      <td className="py-3 px-6 flex space-x-2">
        <ProjectsModalEdit name={row.name} id={row.id} reload={reload} />
        <ProjectsModalDelete name={row.name} id={row.id} reload={reload} />
      </td>
    );
  
    // Domains Actions wrapped with DomainVerificationProvider
    const renderDomainActions = (row) => (
      <DomainVerificationProvider>
        <td className="py-3 px-6 flex space-x-2">
          <DomainsModalDNS reload={reload} name={row.name} id={row.id} />
          <DomainsModalDelete reload={reload} name={row.name} id={row.id} />
        </td>
      </DomainVerificationProvider>
    );
  
    // Mailboxes Actions
    const renderMailboxActions = (row) => (
      <td className="py-3 px-6 flex space-x-2">
        <MailboxesModalConnect mailboxData={row} />
        <MailboxesModalEdit mailboxData={row} reload={reload} />
        <MailboxesModalDelete id={row.id} name={row.first_name} reload={reload} />
      </td>
    );

    // Handle case when no results are found
    const noResults = ipResults.length === 0 && projectResults.length === 0 && domainResults.length === 0 && mailboxResults.length === 0;
  
    return (
      <div>
        <h1>Search Results for "{query}"</h1>

        {/* Show Spinner while loading */}
        {loading && <Spinner />}

        {/* Only show tables if not loading */}
        {!loading && (
          <>
            {/* IP Results */}
            {ipResults.length > 0 && (
              <div>
                <h2 className="text-3xl font-bold mt-4">IPs</h2>
                <Table
                  title={"Connected IPs"}
                  headers={ipHeaders}
                  data={ipResults}
                  preffixColumn={null}
                  suffixColumn={"Actions"}
                  renderPrefix={null}
                  renderSuffix={renderIpActions}
                  customRenderers={{
                    "relay_server_name": renderIP,
                    "relay_server_provider": (cell) => (
                      <td>{highlightMatch(cell, query)}</td>
                    )
                  }}
                  excludeFields={[
                    'relay_server_id',
                    'status',
                    'health',
                    'open_rate',
                    'reply_rate',
                    'bounce_rate',
                    'spam_complaints'
                  ]}
                />
              </div>
            )}

            {/* Project Results */}
            {projectResults.length > 0 && (
              <div>
                <h2 className="text-3xl font-bold mt-4">Projects</h2>
                <Table
                  title={"Projects"}
                  headers={projectHeaders}
                  data={projectResults}
                  preffixColumn={null}
                  suffixColumn={"Actions"}
                  renderPrefix={null}
                  renderSuffix={renderProjectActions}
                  customRenderers={{
                    "name": renderProject
                  }}
                  excludeFields={[
                    'status',
                    'health',
                    'open_rate',
                    'reply_rate',
                    'bounce_rate',
                    'spam_complaints'
                  ]}
                />
              </div>
            )}

            {/* Domain Results */}
            {domainResults.length > 0 && (
              <div>
                <h2 className="text-3xl font-bold mt-4">Domains</h2>
                <DomainVerificationProvider>
                  <Table
                    title={"Domains"}
                    headers={domainHeaders}
                    data={domainResults}
                    preffixColumn={null}
                    suffixColumn={"Actions"}
                    renderPrefix={null}
                    renderSuffix={renderDomainActions}
                    customRenderers={{
                      "name": renderDomain,
                      "status": renderStatus
                    }}
                    excludeFields={[
                      'project_id',
                      'relay_server_id',
                      'health',
                      'open_rate',
                      'reply_rate',
                      'bounce_rate',
                      'spam_complaints'
                    ]}
                  />
                </DomainVerificationProvider>
              </div>
            )}

            {/* Mailbox Results */}
            {mailboxResults.length > 0 && (
              <div>
                <h2 className="text-3xl font-bold mt-4">Mailboxes</h2>
                <Table
                  title={"Mailboxes"}
                  headers={mailboxHeaders}
                  data={mailboxResults}
                  preffixColumn={null}
                  suffixColumn={"Actions"}
                  renderPrefix={null}
                  renderSuffix={renderMailboxActions}
                  customRenderers={{
                    "email": renderMailbox
                  }}
                  excludeFields={[
                    'password',
                    'health',
                    'open_rate',
                    'reply_rate',
                    'bounce_rate',
                    'spam_complaints'
                  ]}
                />
              </div>
            )}

            {/* Handle case when no results are found */}
            {noResults && (
              <div>
                <p>No results found for "{query}".</p>
              </div>
            )}
          </>
        )}
      </div>
    );

};

export default SearchResults;
