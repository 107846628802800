import React, { useState } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import useCachedToken from '../../../useCachedToken.js';  // Import the custom hook
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
    ErrorText: {
        color: 'red',
        listStyleType: 'disc',
        marginLeft: '20px',
        marginBottom: '10px',
    },
};

const DJANGO_HOST = config.djangoHost;

const ProjectsModalDelete = ({ name, id, reload }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const token = useCachedToken();  // Use the custom hook to get the token

    // Function to render the modal title
    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Are you sure you want to delete - {name}?</label>
        );
    };

    // Function to render the modal body
    const renderBody = () => {
        // If loading, display the spinner
        if (loading) {
            return (
                <div style={defaultStyles.SpinnerContainer}>
                    <Spinner />
                </div>
            );
        }

        // Otherwise, display the confirmation message
        return (
            <>
                <p className="text-justify">
                    By deleting this project, all related domains and mailboxes will be deleted and no emails will be sent from them.
                </p>
            </>
        );
    };

    // Function to render the modal options (buttons)
    const renderOptions = (closeModal) => {
        const action = async () => {
            const url = `${DJANGO_HOST}projects/${id}/`;

            setLoading(true);
            setErrors([]); // Clear previous errors

            try {
                if (token) {
                    const response = await fetch(url, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                            'X-Requested-With': 'XMLHttpRequest',
                        }
                    });

                    if (response.status === 204) {
                        reload(); // Refresh data
                        closeModal(); // Close the modal
                    } else if (response.status === 400) {
                        // Handle validation errors returned by the server
                        const data = await response.json();
                        const serverErrors = [];
                        for (const key in data) {
                            if (Array.isArray(data[key])) {
                                data[key].forEach(err => serverErrors.push(`${key}: ${err}`));
                            } else {
                                serverErrors.push(`${key}: ${data[key]}`);
                            }
                        }
                        setErrors(serverErrors);
                    } else {
                        // Handle other types of errors
                        const errorText = await response.text();
                        setErrors(['An unexpected error occurred. Please try again later.']);
                        console.error('Unexpected response:', response.status, errorText);
                    }
                } else {
                    setErrors(['Authentication token is missing. Please log in again.']);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrors(['Failed to delete the project. Please check your network and try again.']);
            } finally {
                setLoading(false);
            }
        };

        return (
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel"
                    disabled={loading} // Disable during loading
                />
                <Button 
                    action={action} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Yes, Delete it"} 
                    disabled={loading}
                    aria-label={loading ? "Processing project deletion" : "Confirm project deletion"}
                />
            </>
        );
    };

    // Function to render error messages
    const renderErrors = () => {
        if (errors.length === 0) return null;
        return (
            <ul className="mb-4">
                {errors.map((error, index) => (
                    <li key={index} style={defaultStyles.ErrorText}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            icon={"delete"} // Assuming Modal accepts an icon prop
            tooltip={"Delete"} // Assuming Modal accepts a tooltip prop
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default ProjectsModalDelete;
