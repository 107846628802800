import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import MyAccount from './components/Settings/MyAccount.js';
import Preferences from './components/Settings/Preferences.js';
import AddOnFeatures from './components/Settings/AddOnFeatures.js';
import Billing from './components/Settings/Billing.js';
import Integrations from './components/Settings/Integrations.js';
import HelpCenter from './components/Settings/HelpCenter.js';
import MenuItem from './components/MenuItem';

const Settings = () => {
  const [selectedSubItem, setSubSelectedItem] = useState('');

  const handleSubMenuItemClick = (item) => {
    setSubSelectedItem(item);
  };

  return (
    <div className="flex h-full">
      <div className="w-1/4 p-4">
        <h1 className="text-2xl font-bold text-black mb-4">Settings</h1>
        <ul className="py-4 flex-1 h-full">
          <MenuItem 
            to="MyAccount" 
            label="My Account" 
            onClick={() => handleSubMenuItemClick('MyAccount')}
            selected={selectedSubItem === 'MyAccount'}
            icon={<div className='mr-4'></div>}
            customClass="text-gray-500 hover:text-white"
          />
          <MenuItem 
            to="Billing" 
            label="Billing" 
            onClick={() => handleSubMenuItemClick('Billing')} 
            selected={selectedSubItem === 'Billing'} 
            icon={<div className='mr-4'></div>}
            customClass="text-gray-500 hover:text-white"
          />
        </ul>
      </div>
      <div className="w-3/4 p-4">
        <Routes>
          <Route path="MyAccount" element={<MyAccount />} />
          <Route path="Billing" element={<Billing />} />
        </Routes>
      </div>
    </div>
  );
};

export default Settings;
