// src/config/env.js

const env = process.env.REACT_APP_ENV || 'development';

const getBaseConfig = () => ({
  development: {
    djangoHost: 'http://localhost:8000/',
    clerkPublishableKey: 'pk_test_dGlnaHQtcmVkYmlyZC00Ni5jbGVyay5hY2NvdW50cy5kZXYk',
    baseRedirectUrl: 'http://localhost:3000/',
    baseSignInUrl: 'https://tight-redbird-46.accounts.dev/sign-in',
    mailInABoxHost: 'emailtest.inboxment.com',
    debug: true,
    featureFlags: {
      newFeature: true,
    },
  },
  production: {
    djangoHost: 'https://api-v2.missioninbox.com/',
    clerkPublishableKey: 'pk_live_Y2xlcmsubWlzc2lvbmluYm94LmNvbSQ',
    baseRedirectUrl: '/ControlCenter',
    baseSignInUrl: 'https://accounts.missioninbox.com/sign-in',
    mailInABoxHost: 'mail.inboxment.com',
    debug: false,
    featureFlags: {
      newFeature: false,
    },
  },
  staging: {
    djangoHost: 'https://staging-api.yourdomain.com',
    clerkPublishableKey: 'pk_test_dGlnaHQtcmVkYmlyZC00Ni5jbGVyay5hY2NvdW50cy5kZXYk',
    baseRedirectUrl: '/ControlCenter',
    baseSignInUrl: 'https://tight-redbird-46.accounts.dev/sign-in',
    mailInABoxHost: 'emailtest.inboxment.com',
    debug: true,
    featureFlags: {
      newFeature: true,
    },
  },
});

const getDynamicConfig = () => {
  const baseConfig = getBaseConfig()[env];
  const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
  
  return {
    ...baseConfig,
    redirectUrl: env === 'development' 
      ? baseConfig.baseRedirectUrl 
      : `https://${hostname}${baseConfig.baseRedirectUrl}`,
    signInUrl: `${baseConfig.baseSignInUrl}?redirect_url=`,
  };
};

const config = getDynamicConfig();

export default {
  ...config,
  env,
  getFullSignInUrl: () => `${config.signInUrl}${encodeURIComponent(config.redirectUrl)}`,
};