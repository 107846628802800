import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../parents/Table.js';
import MailboxesModalConnect from './MailboxesModalConnect.js';
import MailboxesModalDelete from './MailboxesModalDelete.js';
import MailboxesModalDisable from './MailboxesModalDisable.js';
import MailboxesModalEdit from './MailboxesModalEdit.js';
import useCachedToken from '../../../useCachedToken'; // Import the custom hook
import config from '../../../config/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons'; // Changed to a down arrow
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const MailboxesTable = ({ reload }) => {
    const { domain_id } = useParams();
    const [mailboxes, setMailboxes] = useState([]); // Current page mailboxes
    const [totalItems, setTotalItems] = useState(0); // Total number of mailboxes
    const token = useCachedToken(); // Get the token using the custom hook
    const [sortOption, setSortOption] = useState(null); // Initially no sort selected
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown visibility
    const dropdownRef = useRef(null); // Reference for click outside

    // Pagination States
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [itemsPerPage, setItemsPerPage] = useState(20); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    // Loading and Error States
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [selectedMailbox, setSelectedMailbox] = useState(null);

    // When opening the modal, make sure to set the correct data
    const openEditModal = (mailbox) => {
        setSelectedMailbox(mailbox); // Set the selected mailbox data
        // Open your modal here
    };

    // Determine sortBy and sortOrder based on sortOption
    const getSortParameters = (option) => {
        switch (option) {
            case 'Email A-Z':
                return { sortBy: 'email', sortOrder: 'asc' };
            case 'Email Z-A':
                return { sortBy: 'email', sortOrder: 'desc' };
            case 'Latest':
                return { sortBy: 'created_at', sortOrder: 'desc' };
            case 'Oldest':
                return { sortBy: 'created_at', sortOrder: 'asc' };
            default:
                return { sortBy: 'created_at', sortOrder: 'desc' }; // Default sort
        }
    };

    const { sortBy, sortOrder } = getSortParameters(sortOption);

    let url = `${DJANGO_HOST}mailbox/?sort_by=${sortBy}&sort_order=${sortOrder}`;

    if (domain_id) {
        url += `&domain_id=${domain_id}`;
    }

    const headers = [
        { name: "Password", available: true },
        { name: "First Name", available: true },
        { name: "Last Name", available: true },
        { name: "Sent Emails", available: true },
        { name: "Reply-To", available: true }
    ];

    // Fetch mailboxes from the API
    const fetchMailboxes = useCallback(async () => {
        setIsLoading(true);
        setErrors([]);
        try {
            const limit = itemsPerPage;
            const offset = (currentPage - 1) * itemsPerPage;
            const fetchUrl = `${url}&limit=${limit}&offset=${offset}`;

            const response = await fetch(fetchUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` // Add the token to the request headers
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch mailboxes. Status: ${response.status}`);
            }
            const data = await response.json();
            console.log('Fetched Mailboxes:', data); // Debug: check fetched data

            setTotalItems(data.count || 0);
            setMailboxes(Array.isArray(data.results) ? data.results : []);
        } catch (error) {
            console.error('Error fetching mailboxes:', error);
            setErrors([error.message || 'An unexpected error occurred while fetching mailboxes.']);
            setMailboxes([]); // Reset to empty array on error
        } finally {
            setIsLoading(false);
        }
    }, [url, token, currentPage, itemsPerPage]);

    // Fetch mailboxes when component mounts or dependencies change
    useEffect(() => {
        if (token) {
            fetchMailboxes();
        }
    }, [fetchMailboxes, token]);

    // Calculate totalPages whenever totalItems or itemsPerPage changes
    useEffect(() => {
        const total = Math.ceil(totalItems / itemsPerPage) || 1;
        setTotalPages(total);
    }, [totalItems, itemsPerPage]);

    // Ensure currentPage is within totalPages
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages);
        }
    }, [totalPages]);

    // Handle sort option selection
    const handleSortOptionSelect = (option) => {
        setSortOption(option);
        setIsDropdownOpen(false); // Close dropdown after selection
        setCurrentPage(1); // Reset to first page on sort
    };

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handlePrefixAction = (option, id) => { };

    const handleSuffixAction = (option, id) => { };

    // Updated renderPrefix function
    const renderPrefix = (row) => {
        if (row.email && row.domain_name) {
            return (
                <td className="py-3 px-2">
                    {`${row.email}@${row.domain_name}`} {/* Concatenate mailbox and domain */}
                </td>
            );
        } else {
            // Log a warning if email or domain is missing
            console.warn('Missing email or domain for row:', row);
            return <td className="py-3 px-2">N/A</td>;
        }
    };

    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex">
                <MailboxesModalConnect key={`connect-${row.id}`} mailboxData={row} />
                <MailboxesModalEdit key={`edit-${row.id}`} mailboxData={row} reload={reload} />
                <MailboxesModalDelete key={`delete-${row.id}`} id={row.id} name={row.first_name} reload={reload} />
            </td>
        );
    };

    // Handle itemsPerPage change
    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page on itemsPerPage change
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber === 'left-ellipsis' || pageNumber === 'right-ellipsis') {
            // Do nothing for ellipses
            return;
        }
        setCurrentPage(pageNumber);
    };

    // Updated getPageNumbers function with ellipses
    const getPageNumbers = () => {
        const pages = [];

        if (totalPages <= 3) {
            // If there are 3 or fewer pages, show all page numbers
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Determine when to show ellipses and which page numbers to display
            if (currentPage > 2) {
                pages.push('left-ellipsis');
            }

            // Always show currentPage and its immediate neighbors
            const startPage = Math.max(1, currentPage - 1);
            const endPage = Math.min(totalPages, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (currentPage < totalPages - 1) {
                pages.push('right-ellipsis');
            }
        }

        return pages;
    };

    // Style Objects
    const styles = {
        headerButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
            position: 'relative', // To position dropdown absolutely
        },
        sortDropdownButton: {
            backgroundColor: '#17a2b8',
            color: 'white',
            padding: '8px 12px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            whiteSpace: 'nowrap', // Prevent text wrapping
        },
        dropdownMenu: {
            position: 'absolute',
            top: '100%',
            right: '0',
            backgroundColor: 'white',
            listStyle: 'none',
            padding: '0',
            margin: '5px 0 0 0',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1000,
            minWidth: '150px',
        },
        dropdownItem: (isSelected) => ({
            padding: '10px 15px',
            cursor: 'pointer',
            backgroundColor: isSelected ? '#e0f7fa' : 'white',
            transition: 'background-color 0.2s',
        }),
        showLabel: {
            marginRight: '10px',
        },
        itemsPerPageContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
        },
        itemsPerPageSelect: {
            padding: '6px 10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            width: '100px',
        },
        paginationContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            gap: '5px',
        },
        paginationButton: (isActive, isDisabled) => ({
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: isActive ? '#17a2b8' : 'white',
            color: isActive ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.6 : 1,
        }),
        paginationEllipsis: {
            padding: '8px 12px',
        },
        spinnerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 0',
        },
        errorContainer: {
            color: 'red',
            marginBottom: '10px',
            textAlign: 'center',
        },
    };

    const renderErrors = () => {
        if (errors.length === 0) return null;
        return (
            <div style={styles.errorContainer}>
                <ul>
                    {errors.map((error, index) => (
                        <li key={index}>
                            - {error}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div>
            <div style={styles.headerButtons}>
                {/* Sort and Items Per Page Controls */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {/* Sort By Dropdown Button */}
                    <div style={{ position: 'relative' }} ref={dropdownRef}>
                        <button
                            onClick={toggleDropdown}
                            style={styles.sortDropdownButton}
                            aria-haspopup="listbox"
                            aria-expanded={isDropdownOpen}
                            aria-label="Sort mailboxes"
                        >
                            {/* Button Label */}
                            <span>{sortOption ? sortOption : 'Sort by'}</span>
                            {/* Down Arrow Icon */}
                            <FontAwesomeIcon icon={faSortDown} />
                        </button>

                        {/* Dropdown Menu */}
                        {isDropdownOpen && (
                            <ul
                                role="listbox"
                                style={styles.dropdownMenu}
                            >
                                {['Email A-Z', 'Email Z-A', 'Latest', 'Oldest'].map(option => (
                                    <li
                                        key={option}
                                        onClick={() => handleSortOptionSelect(option)}
                                        role="option"
                                        aria-selected={sortOption === option}
                                        style={styles.dropdownItem(sortOption === option)}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = sortOption === option ? '#e0f7fa' : 'white'}
                                    >
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* Items Per Page Selector */}
                    <div style={styles.itemsPerPageContainer}>
                        <label htmlFor="itemsPerPage" style={{ marginRight: '5px' }}>Show:</label>
                        <select
                            id="itemsPerPage"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            style={styles.itemsPerPageSelect}
                            disabled={isLoading} // Disable during loading
                            aria-label="Select number of mailboxes to display per page"
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Error Messages */}
            {renderErrors()}

            {/* Loading Spinner */}
            {isLoading ? (
                <div style={styles.spinnerContainer}>
                    <Spinner />
                </div>
            ) : (
                <>
                    {/* Table Component */}
                    <Table
                        headers={headers}
                        title={"Mailboxes"}
                        preffixColumn={"Mailbox"} // Set prefix column to a valid name
                        suffixColumn={"Actions"}
                        renderPrefix={renderPrefix}
                        renderSuffix={renderSuffix}
                        handlePrefixAction={handlePrefixAction}
                        handleSuffixAction={handleSuffixAction}
                        data={mailboxes}
                        excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints', 'health', 'email', 'domain_name']}
                    />

                    {/* Pagination Controls */}
                    <div style={styles.paginationContainer}>
                        {/* Previous Button */}
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={styles.paginationButton(false, currentPage === 1)}
                            aria-label="Previous Page"
                        >
                            &laquo;
                        </button>

                        {/* Page Numbers and Ellipses */}
                        {getPageNumbers().map((item, index) => {
                            if (item === 'left-ellipsis' || item === 'right-ellipsis') {
                                return (
                                    <span key={index} style={styles.paginationEllipsis}>
                                        &hellip;
                                    </span>
                                );
                            }

                            return (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(item)}
                                    style={styles.paginationButton(currentPage === item, false)}
                                    aria-current={currentPage === item ? 'page' : undefined}
                                    aria-label={`Page ${item}`}
                                >
                                    {item}
                                </button>
                            );
                        })}

                        {/* Next Button */}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={styles.paginationButton(false, currentPage === totalPages)}
                            aria-label="Next Page"
                        >
                            &raquo;
                        </button>
                    </div>
                </>
            )}
        </div>
    );

};

export default MailboxesTable;
