import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ControlCenterIPsTable from './components/ControlCenter/ControlCenterIPsTable.js';
import ControlCenterProjectsTable from './components/ControlCenter/ControlCenterProjectsTable.js';
import ControlCenterDomainsTable from './components/ControlCenter/ControlCenterDomainsTable.js';
import ControlCenterMailboxesTable from './components/ControlCenter/ControlCenterMailboxesTable.js';

import ConnectedIpsModalAddIp from './components/ConnectedIPs/ConnectedIpsModalAddIp.js';
import ProjectsModalAdd from './components/Projects/ProjectsModalAdd.js';
import DomainsModalAdd from './components/Domains/DomainsModalAdd.js';
import MailboxesModalAdd from './components/Mailboxes/MailboxesModalAdd.js';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import config from '../config/env'; // Assuming the config file location
import { useAuth } from '@clerk/clerk-react'; // Importing useAuth to get the token

const ControlCenter = () => {
  const DJANGO_HOST = config.djangoHost; // Same as in ConnectedIpsIPsTable
  const [cardData, setCardData] = useState([
    {
      label: 'Sent Emails',
      data: 'Loading...',
      change: '-9506',
      status: 'decrease'
    },
  ]);
  const [reloadKey, setReloadKey] = useState(0);
  const { getToken } = useAuth(); // Use the useAuth hook to get the token

  const triggerReload = useCallback(() => {
    setReloadKey((prevKey) => prevKey + 1);
  }, []);

  const fetchTotalSentEmails = useCallback(async () => {
    const url = `${DJANGO_HOST}user_relay_server/total-sent-emails/`; // Use config to construct URL
    try {
      console.log("Making request to:", url);
      
      const token = await getToken(); // Get the token

      // Send the request with the token
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in Authorization header
        }
      });

      console.log("API response:", response);

      const totalSentEmails = response.data.total_sent_emails;

      // Log the total sent emails value
      console.log("Total Sent Emails:", totalSentEmails);

      // Update cardData with the fetched data
      setCardData((prevData) =>
        prevData.map((item, index) => {
          if (index === 0) {
            return { ...item, data: totalSentEmails.toLocaleString() };
          }
          return item;
        })
      );
    } catch (error) {
      console.error('Error fetching total sent emails:', error);
    }
  }, [DJANGO_HOST, getToken]);

  useEffect(() => {
    fetchTotalSentEmails(); // Fetch data on component mount
  }, [fetchTotalSentEmails]);

  return (
    <div className="p-6 font-sans">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Control Center</h1>
      <h2 className="text-xl font-bold text-gray-600 mb-6">Overall Overview</h2>
      <div className="flex justify-between mb-12 space-x-4">
        {cardData.map((item, index) => (
          index === 0 ? (
            <div key={index} className="flex-1 p-4 bg-white shadow rounded-md">
              <div className="text-lg font-semibold text-gray-700">{item.label}</div>
              <div className="text-2xl font-bold text-gray-800">{item.data}</div>
            </div>
          ) : (
            <div key={index} className="flex-1 p-4 bg-white shadow rounded-md placeholder-parent">
              <div className="placeholder-overlay rounded-md">Coming Soon</div>
              <div className="text-lg font-semibold text-gray-700">{item.label}</div>
              <div className="text-2xl font-bold text-gray-800">{item.data}</div>
            </div>
          )
        ))}
      </div>

      {/* IPs Section */}
      <div key={reloadKey} className="mt-12">
        <div className="flex justify-between px-10">
          <Link to="/ConnectedIps" className="font-bold text-lg hover:underline">
            IPs
          </Link>
          <ConnectedIpsModalAddIp reload={triggerReload} reloadKey={reloadKey} />
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterIPsTable reload={triggerReload} reloadKey={reloadKey} />
        </div>

        {/* Projects Section */}
        <div className="flex justify-between px-10 mt-8">
          <Link to="/Projects" className="font-bold text-lg hover:underline">
            Projects
          </Link>
          <ProjectsModalAdd reload={triggerReload}></ProjectsModalAdd>
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterProjectsTable reload={triggerReload} reloadKey={reloadKey} />
        </div>

        {/* Domains Section */}
        <div className="flex justify-between px-10 mt-8">
          <Link to="/Domains" className="font-bold text-lg hover:underline">
            Domains
          </Link>
          <DomainsModalAdd reload={triggerReload} />
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterDomainsTable reload={triggerReload} reloadKey={reloadKey} />
        </div>

        {/* Mailboxes Section */}
        <div className="flex justify-between px-10 mt-8">
          <Link to="/Mailboxes" className="font-bold text-lg hover:underline">
            Mailboxes
          </Link>
          <MailboxesModalAdd reload={triggerReload} />
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterMailboxesTable reload={triggerReload} reloadKey={reloadKey} />
        </div>
      </div>

      <Toaster />
    </div>
  );
}

export default ControlCenter;
