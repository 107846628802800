import React, { useState } from 'react';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';

const Table = ({
    title,
    headers,
    preffixColumn,
    suffixColumn,
    renderPrefix,
    renderSuffix,
    customRenderers,
    data,
    excludeFields = []
}) => {
    const [copiedIndex, setCopiedIndex] = useState(null);

    if (!data) {
        return <Spinner />;
    }

    if (data.length === 0) {
        return <label>There are currently no elements in {title}</label>;
    }

    const columnCount = Object.keys(data[0] || {})
        .filter(key =>
            key !== 'id' &&
            key !== 'icon' &&
            key !== 'active' &&
            key !== 'starred' &&
            key !== 'relay_server_slug' &&
            key !== 'project_slug' &&
            !excludeFields.includes(key)
        ).length
        + (preffixColumn ? 1 : 0)
        + (suffixColumn ? 1 : 0);

    const copyToClipboard = (text, rowIndex) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedIndex(rowIndex);
            setTimeout(() => setCopiedIndex(null), 2000);
        });
    };

    const renderCell = (key, cell, row, rowIndex) => {
        if (customRenderers && customRenderers[key]) {
            return customRenderers[key](cell, row);
        }

        const isValueColumn = key === 'value';
        const isStatusColumn = key === 'status' || key === 'verified';

        const cellContent = isStatusColumn
            ? (cell ? 'Verified' : 'Not Verified')
            : cell;

        return (
            <td
                key={key}
                className={`py-3 px-3 text-left text-balance cell-wrap relative
                ${isValueColumn ? 'value-column' : ''}
                ${isStatusColumn ? 'host-column' : ''}
                ${key === 'host' ? 'host-column' : ''} 
                ${typeof cell === 'boolean' ? (cell ? 'cell-true' : 'cell-false') : ''}`}
            >
                {isValueColumn ? (
                    <>
                        <div className="group">
                            {cellContent}
                            <button
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity"
                                onClick={() => copyToClipboard(cellContent, rowIndex)}
                            >
                                📋
                            </button>
                        </div>
                        {copiedIndex === rowIndex && (
                            <span className="absolute right-8 top-1/2 transform -translate-y-1/2 text-green-500">
                                Copied!
                            </span>
                        )}
                    </>
                ) : (
                    cellContent
                )}
            </td>
        );
    };

    return (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse font-sans">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 text-sm leading-normal font-semibold">
                            {preffixColumn && (
                                <th className="py-3 px-3 text">{preffixColumn}</th>
                            )}
                            {headers.map((header, index) => (
                                !excludeFields.includes(header.name.toLowerCase()) && ( // Skip rendering excluded fields
                                    <th
                                        key={index}
                                        className={`py-3 px-3 text-left ${header.key === 'host' ? 'host-column' : ''} ${!header.available ? 'bg-gray-100 text-gray-500 cursor-not-allowed relative group' : ''}`}
                                    >
                                        {header.name}
                                        {!header.available && (
                                            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-gray-500 bg-opacity-75">
                                                <span className="text-white">Coming Soon</span>
                                            </div>
                                        )}
                                    </th>
                                )
                            ))}
                            {suffixColumn && (
                                <th className="py-3 px-3 text-left">{suffixColumn}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {data.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className={rowIndex % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'hover:bg-gray-100'}
                            >
                                {preffixColumn && renderPrefix(row)}
                                {Object.entries(row)
                                    .filter(([key]) => 
                                        key !== 'id' && 
                                        key !== 'icon' && 
                                        key !== 'active' && 
                                        key !== 'starred' && 
                                        key !== 'relay_server_slug' && 
                                        key !== 'project_slug' && 
                                        !excludeFields.includes(key)
                                    )
                                    .map(([key, cell]) => renderCell(key, cell, row, rowIndex))}
                                {suffixColumn && renderSuffix(row)}
                            </tr>
                        ))}
                        <tr className={data.length % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'hover:bg-gray-100'}>
                            {[...Array(columnCount)].map((_, index) => (
                                <td key={index} className="py-3 px-3 text-left whitespace-nowrap">
                                    <span className='invisible'>A</span>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;
