import React from "react";

const defaultStyles = {
    Button : {
        padding: '8px 12px', // Match size of the other buttons
        border: 'none',
        margin: '2px',
        borderRadius: '4px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#17a2b8', // Same color as the sort button
        color: '#ffffff',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    Label : {
        color : 'red'
    }
};

const defaultLabel = <label style={defaultStyles.Label}>ErrorButton</label>;

const Button = ({ label=defaultLabel, style=defaultStyles.Button, action, rightIcon }) => {
    return (
        <button style={style} onClick={action}>{label} {rightIcon}</button>
    );
};

export default Button;