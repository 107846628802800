import React, { useState, useEffect } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Input from "../parents/Input.js";
import { useAuth } from '@clerk/clerk-react';
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    BodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    Section: {
        // Add your styles here
    },
    SectionTitle: {
        fontWeight: 'bold',
    },
    InputGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
    },
    PasswordGroup: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    InputBox: {
        flex: 1,
    },
    ShowHideButton: {
        // Add your styles here
    },
    ResetButton: {
        // Add your styles here
    },
    ErrorList: {
        listStyleType: 'disc',
        marginLeft: '20px',
    },
    ErrorItem: {
        color: 'red',
    },
    SuccessMessage: {
        color: 'green',
        listStyleType: 'none',
        marginLeft: '20px',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
};

const MailboxesModalEdit = ({ mailboxData, reload }) => {
    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [firstName, setFirstName] = useState(mailboxData.first_name);
    const [lastName, setLastName] = useState(mailboxData.last_name);
    const [mailboxName, setMailboxName] = useState(mailboxData.email);
    const [password, setPassword] = useState(mailboxData.password);
    const [autoReply, setAutoReply] = useState('');
    const [isPasswordEditable, setIsPasswordEditable] = useState(false);
    const [replyTo, setReplyTo] = useState(mailboxData.reply_to || '');
    const { getToken } = useAuth();
    const [newPassword, setNewPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);

    // Synchronize state with props
    useEffect(() => {
        setFirstName(mailboxData.first_name);
        setLastName(mailboxData.last_name);
        setMailboxName(mailboxData.email);
        setPassword(mailboxData.password);
        setReplyTo(mailboxData.reply_to || '');
        setNewPassword('');
        setIsPasswordEditable(false);
        setErrors([]);
        setSuccessMessage('');
    }, [mailboxData]);

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to reset password
    const resetPassword = async () => {
        setIsResettingPassword(true);
        setErrors([]);
        try {
            const url = `${DJANGO_HOST}mailbox/${mailboxData.id}/`;
            const token = await getToken();
            if (!token) {
                throw new Error("Authentication token is missing. Please log in again.");
            }

            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify({ password: newPassword }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.detail || 'Failed to reset password.';
                throw new Error(errorMessage);
            }

            const data = await response.json();
            setPassword(data.password);
            setIsPasswordEditable(false);
            setNewPassword('');
            setSuccessMessage('Password reset successfully!');
            reload(); // Refresh data
        } catch (error) {
            setErrors([error.message || 'Failed to reset password.']);
            console.error('Error resetting password:', error);
        } finally {
            setIsResettingPassword(false);
        }
    };

    // Function to handle saving the form
    const handleSave = async (closeModal) => {
        // Clear errors and success message
        setErrors([]);
        setSuccessMessage('');

        // Input Validation
        const newErrors = [];

        if (!firstName.trim()) {
            newErrors.push('First Name cannot be empty.');
        }

        if (!lastName.trim()) {
            newErrors.push('Last Name cannot be empty.');
        }

        if (!mailboxName.trim()) {
            newErrors.push('Mailbox Name cannot be empty.');
        }

        if (replyTo && !emailRegex.test(replyTo)) {
            newErrors.push('Invalid Reply-To email format.');
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsSubmitting(true);

        const payload = {
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            email: mailboxName.trim(),
            password, // Assuming password is handled separately
            auto_reply: autoReply,
            reply_to: replyTo.trim() || '', // Explicitly set empty string if replyTo is empty
        };

        try {
            const url = `${DJANGO_HOST}mailbox/${mailboxData.id}/`;
            const token = await getToken();
            if (!token) {
                throw new Error("Authentication token is missing. Please log in again.");
            }

            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.detail || 'Failed to update mailbox.';
                throw new Error(errorMessage);
            }

            const responseData = await response.json();

            setSuccessMessage('Mailboxes updated successfully!');

            // Optionally, you can use a toast notification here
            // Example: toast.success('Mailboxes updated successfully!');

            // Wait for 2 seconds before closing modal and reloading
            setTimeout(() => {
                handleCloseModal(closeModal);
                reload();
            }, 2000);
        } catch (error) {
            setErrors([error.message || 'Failed to update mailbox.']);
            console.error('Error updating mailbox:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Function to handle closing the modal
    const handleCloseModal = (closeModal) => {
        closeModal(); // Close the modal
        setSuccessMessage('');
        setErrors([]);
    };

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Mailbox Configuration</label>
        );
    };

    const renderSubtitle = () => {
        return (
            <p style={defaultStyles.SectionTitle}>Edit the sender information and autoreply setup</p>
        );
    };

    const renderBody = () => {
        return (
            <div style={defaultStyles.BodyContainer}>
                {renderSubtitle()}
                <section style={defaultStyles.Section}>
                    <h3 style={defaultStyles.SectionTitle}>Sender Information</h3>
                    <div style={defaultStyles.InputGroup}>
                        <Input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First Name"
                            style={defaultStyles.InputBox}
                            disabled={isSubmitting}
                        />
                        <Input
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last Name"
                            style={defaultStyles.InputBox}
                            disabled={isSubmitting}
                        />
                    </div>
                </section>
                <section style={defaultStyles.Section}>
                    <h3 style={defaultStyles.SectionTitle}>Mailbox Information</h3>
                    <div style={defaultStyles.InputGroup}>
                        <p>Mailbox: {mailboxName}</p>
                    </div>
                    <div style={defaultStyles.PasswordGroup}>
                        <p>Password</p>
                        {isPasswordEditable ? (
                            <Input
                                type="text"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter new password"
                                style={defaultStyles.InputBox}
                                disabled={isResettingPassword}
                            />
                        ) : (
                            <div
                                onClick={() => setIsPasswordEditable(true)}
                                style={{
                                    ...defaultStyles.InputBox,
                                    cursor: 'pointer',
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                }}
                            >
                                {password}
                            </div>
                        )}
                        <Button
                            action={resetPassword}
                            label={isResettingPassword ? "Processing..." : "Reset Password"}
                            style={isResettingPassword ? { ...defaultStyles.AcceptButton, cursor: 'not-allowed', opacity: 0.6 } : defaultStyles.AcceptButton}
                            disabled={isResettingPassword || !isPasswordEditable || !newPassword.trim()}
                            aria-label="Reset password"
                        />
                    </div>
                </section>
                <section style={defaultStyles.Section}>
                    <h3 style={defaultStyles.SectionTitle}>Reply-To (Optional)</h3>
                    <Input
                        value={replyTo}
                        onChange={(e) => setReplyTo(e.target.value)}
                        placeholder="Enter Reply-To email"
                        style={defaultStyles.InputBox}
                        disabled={isSubmitting}
                    />
                </section>
            </div>
        );
    };

    const renderOptions = (closeModal) => {
        return (
            <>
                <Button
                    action={() => handleCloseModal(closeModal)}
                    style={defaultStyles.CancelButton}
                    label="Cancel"
                    disabled={isSubmitting || isResettingPassword}
                    aria-label="Cancel editing mailbox"
                />
                <Button
                    action={() => handleSave(closeModal)}
                    style={isSubmitting ? { ...defaultStyles.DisabledAcceptButton, opacity: 0.6 } : defaultStyles.AcceptButton}
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting || isResettingPassword}
                    aria-label={isSubmitting ? "Saving mailbox changes" : "Confirm saving mailbox changes"}
                />
            </>
        );
    };

    const renderErrorsAndSuccess = () => {
        return (
            <>
                {errors.length > 0 && (
                    <ul style={defaultStyles.ErrorList}>
                        {errors.map((error, index) => (
                            <li key={index} style={defaultStyles.ErrorItem}>
                                - {error}
                            </li>
                        ))}
                    </ul>
                )}
                {successMessage && (
                    <ul style={defaultStyles.SuccessMessage}>
                        <li>
                            - {successMessage}
                        </li>
                    </ul>
                )}
                {/* Show spinner overlay during submission */}
                {(isSubmitting || isResettingPassword) && (
                    <div style={defaultStyles.SpinnerContainer}>
                        <Spinner />
                    </div>
                )}
            </>
        );
    };

    return (
        <Modal
            icon={"edit"}
            tooltip={"Edit"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrorsAndSuccess} // Render both errors and success message
        />
    );
};

export default MailboxesModalEdit;
