import React, { useState } from "react";
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Input from "../parents/Input.js";
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    ErrorText: {
        color: 'red',
        listStyleType: 'disc',
        marginLeft: '20px',
        marginBottom: '10px',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
};

const DJANGO_HOST = config.djangoHost;

const ConnectedIpsModalEdit = ({ name, id, reload }) => {
    const [newName, setNewName] = useState("");
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getToken } = useAuth();

    // Function to render the modal title
    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Edit - {name}</label>
        );
    };

    // Function to render the modal body
    const renderBody = () => {
        // If loading, display the spinner
        if (isLoading) {
            return (
                <div style={defaultStyles.SpinnerContainer}>
                    <Spinner />
                </div>
            );
        }

        // Otherwise, display the form
        return (
            <>
                <p className="text-justify">
                    Change the name of your IP.
                </p>
                <br />
                <br />
                <div className="pb-4">
                    <Input 
                        name="newName" 
                        placeholder="Name" 
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)} 
                        disabled={isLoading} // Disable input during loading
                    />
                </div>
            </>
        );
    };

    // Function to render the modal options (buttons)
    const renderOptions = (closeModal) => {
        const action = async () => {
            const newErrors = [];

            // Regular expression to allow letters, numbers, hyphens, underscores, em dashes, and spaces
            const nameRegex = /^[a-zA-Z0-9-_— ]+$/;

            // Input validation
            if (!newName.trim()) {
                newErrors.push('Name cannot be empty.');
            } else if (!nameRegex.test(newName)) {
                newErrors.push('IP name can only contain letters, numbers, hyphens (-), underscores (_), em dashes (—), and spaces.');
            }

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            setErrors([]);
            setIsLoading(true); // Start loading

            const url = `${DJANGO_HOST}relay_servers/${id}/`;

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify({
                        'name': newName.trim(),
                    }),
                });

                if (response.status === 200) {
                    reload(); // Refresh data
                    closeModal(); // Close the modal
                } else if (response.status === 400) {
                    // Handle validation errors returned by the server
                    const data = await response.json();
                    const serverErrors = [];
                    for (const key in data) {
                        if (Array.isArray(data[key])) {
                            data[key].forEach(err => serverErrors.push(`${key}: ${err}`));
                        } else {
                            serverErrors.push(`${key}: ${data[key]}`);
                        }
                    }
                    setErrors(serverErrors);
                } else {
                    // Handle other types of errors
                    const errorText = await response.text();
                    setErrors(['An unexpected error occurred. Please try again later.']);
                    console.error('Unexpected response:', response.status, errorText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrors(['Failed to update the IP. Please check your network and try again.']);
            }

            setIsLoading(false); // Stop loading
        };

        return (
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel"
                    disabled={isLoading} // Disable button during loading
                />
                <Button 
                    action={action} 
                    style={isLoading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={isLoading ? "Processing..." : "Update"} 
                    disabled={isLoading}
                />
            </>
        );
    };

    // Function to render error messages
    const renderErrors = () => {
        if (errors.length === 0) return null;
        return (
            <ul className="mb-4">
                {errors.map((error, index) => (
                    <li key={index} style={defaultStyles.ErrorText}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            icon={"edit"} // Assuming Modal accepts an icon prop
            tooltip={"Edit"} // Assuming Modal accepts a tooltip prop
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default ConnectedIpsModalEdit;
