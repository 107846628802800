import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Table from '../parents/Table.js';
import ProjectsModalDelete from './ProjectsModalDelete.js';
import ProjectsModalEdit from './ProjectsModalEdit.js';
import useCachedToken from '../../../useCachedToken'; 
import config from '../../../config/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const ProjectsTable = ({ reload }) => {
    const [projects, setProjects] = useState([]); // Projects to display on the current page
    const [totalItems, setTotalItems] = useState(0); // Total number of projects
    const token = useCachedToken();

    // State for sorting
    const [sortOption, setSortOption] = useState(null); // Initially no sort selected
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown visibility
    const dropdownRef = useRef(null); // Reference for click outside

    // Pagination States
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [itemsPerPage, setItemsPerPage] = useState(20); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    // Loading and Error States
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    // Determine sortBy and sortOrder based on sortOption
    const getSortParameters = (option) => {
        switch (option) {
            case 'Name A-Z':
                return { sortBy: 'name', sortOrder: 'asc' };
            case 'Name Z-A':
                return { sortBy: 'name', sortOrder: 'desc' };
            case 'Latest':
                return { sortBy: 'created_at', sortOrder: 'desc' };
            case 'Oldest':
                return { sortBy: 'created_at', sortOrder: 'asc' };
            default:
                return { sortBy: 'created_at', sortOrder: 'desc' }; // Default sort
        }
    };

    const { sortBy, sortOrder } = getSortParameters(sortOption);

    const headers = [
        { name: "Project", available: true },
        { name: "Domains", available: true },
        { name: "Mailboxes", available: true },
        { name: "Sent Emails", available: true }
    ];

    // Fetch projects from the API
    const fetchProjects = async () => {
        setIsLoading(true);
        setErrors([]);
        try {
            const limit = itemsPerPage;
            const offset = (currentPage - 1) * itemsPerPage;
            const fetchUrl = `${DJANGO_HOST}projects/?sort_by=${sortBy}&sort_order=${sortOrder}&limit=${limit}&offset=${offset}`;

            const response = await fetch(fetchUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            
            // Log the fetched data
            console.log("Fetched Projects:", data.results);

            setTotalItems(data.count || 0);
            setProjects(Array.isArray(data.results) ? data.results : []);
        } catch (error) {
            console.error('Error fetching projects:', error);
            setProjects([]); // Reset to empty array on error
            setTotalItems(0);
            setErrors(['Failed to fetch projects. Please try again later.']);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch projects when component mounts or dependencies change
    useEffect(() => {
        if (token) {
            fetchProjects();
        }
    }, [token, sortOption, currentPage, itemsPerPage]); // Include currentPage and itemsPerPage as dependencies

    // Calculate totalPages whenever totalItems or itemsPerPage changes
    useEffect(() => {
        const total = Math.ceil(totalItems / itemsPerPage) || 1;
        setTotalPages(total);

        // Ensure currentPage is within totalPages
        if (currentPage > total) {
            setCurrentPage(total);
        }
    }, [totalItems, itemsPerPage]);

    // Handle sort option selection
    const handleSortOptionSelect = (option) => {
        setSortOption(option);
        setIsDropdownOpen(false); // Close dropdown after selection
        setCurrentPage(1); // Reset to first page on sort
    };

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    // Render the project name as a link with properly encoded slugs
    const renderProjectLink = (cell, row) => {
        const projectId = row.id;
        return (
            <td className="py-3 px-6 whitespace-normal break-words">
                <Link to={`/navigate/project/${projectId}/`}>
                    {cell}
                </Link>
            </td>
        );
    };

    // Render the Actions column with Edit and Delete modals
    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex whitespace-normal break-words">
                <ProjectsModalEdit name={row.name} id={row.id} reload={reload} />
                <ProjectsModalDelete name={row.name} id={row.id} reload={reload} />
            </td>
        );
    };

    // Pagination Helper Function
    const getPageNumbers = () => {
        const pages = [];

        if (totalPages <= 3) {
            // If there are 3 or fewer pages, show all page numbers
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Determine when to show ellipses and which page numbers to display
            if (currentPage > 2) {
                pages.push('left-ellipsis');
            }

            // Always show currentPage and its immediate neighbors
            const startPage = Math.max(1, currentPage - 1);
            const endPage = Math.min(totalPages, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (currentPage < totalPages - 1) {
                pages.push('right-ellipsis');
            }
        }

        return pages;
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber === 'left-ellipsis') {
            setCurrentPage(1); // Jump to first page
            return;
        }
        if (pageNumber === 'right-ellipsis') {
            setCurrentPage(totalPages); // Jump to last page
            return;
        }
        setCurrentPage(pageNumber);
    };

    // Handle itemsPerPage change
    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1); // Reset to first page on itemsPerPage change
    };
  
    // Style Objects
    const styles = {
        headerButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
            position: 'relative', // To position dropdown absolutely
        },
        itemsPerPageContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
        },
        sortDropdownButton: {
            backgroundColor: '#17a2b8',
            color: 'white',
            padding: '8px 12px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            whiteSpace: 'nowrap', // Prevent text wrapping
        },
        dropdownMenu: {
            position: 'absolute',
            top: '100%',
            right: '0',
            backgroundColor: 'white',
            listStyle: 'none',
            padding: '0',
            margin: '5px 0 0 0',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1000,
            minWidth: '150px',
        },
        dropdownItem: (isSelected) => ({
            padding: '10px 15px',
            cursor: 'pointer',
            backgroundColor: isSelected ? '#e0f7fa' : 'white',
            transition: 'background-color 0.2s',
        }),
        showLabel: {
            marginRight: '10px',
        },
        itemsPerPageSelect: {
            padding: '6px 10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            width: '150px', // Increased width to prevent overlapping
        },
        paginationContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            gap: '5px',
        },
        paginationButton: (isActive, isDisabled) => ({
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: isActive ? '#17a2b8' : 'white',
            color: isActive ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.6 : 1,
        }),
        paginationEllipsis: {
            padding: '8px 12px',
        },
        spinnerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 0',
        },
        errorContainer: {
            color: 'red',
            marginBottom: '10px',
        },
    };


    return (
        <div>
          <div style={styles.headerButtons}>
            {/* Sort and Items Per Page Controls */}
            <div style={styles.itemsPerPageContainer}>
              {/* Sort By Dropdown Button */}
              <div style={{ position: 'relative' }} ref={dropdownRef}>
                <button
                  onClick={() => toggleDropdown(!isDropdownOpen)}
                  style={styles.sortDropdownButton}
                  aria-haspopup="listbox"
                  aria-expanded={isDropdownOpen}
                >
                  {/* Button Label */}
                  <span>{sortOption ? sortOption : 'Sort by'}</span>
                  {/* Down Arrow Icon */}
                  <FontAwesomeIcon icon={faSortDown} />
                </button>
    
                {/* Dropdown Menu */}
                {isDropdownOpen && (
                  <ul
                    role="listbox"
                    style={styles.dropdownMenu}
                  >
                    {['Name A-Z', 'Name Z-A', 'Latest', 'Oldest'].map(option => (
                      <li
                        key={option}
                        onClick={() => {
                          handleSortOptionSelect(option);
                          toggleDropdown(false);
                        }}
                        role="option"
                        aria-selected={sortOption === option}
                        style={styles.dropdownItem(sortOption === option)}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = sortOption === option ? '#e0f7fa' : 'white'}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
    
              {/* Items Per Page Selector */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="itemsPerPage" style={styles.showLabel}>Show:</label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                  style={styles.itemsPerPageSelect}
                  disabled={isLoading} // Disable during loading
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
    
          {/* Error Messages */}
          {errors.length > 0 && (
            <div style={styles.errorContainer}>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
    
          {/* Loading Spinner */}
          {isLoading ? (
            <div style={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <>
              {/* Table Component */}
              <Table
                title={"Projects"}
                headers={headers}
                data={projects} // Pass the current page's projects
                preffixColumn={null}
                suffixColumn={"Actions"}
                renderPrefix={null}
                renderSuffix={renderSuffix}
                customRenderers={{ "name": renderProjectLink }} 
                excludeFields={['status', 'health', 'open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}
              />
    
              {/* Pagination Controls */}
              {totalPages > 1 && (
                <div style={styles.paginationContainer}>
                  {/* Previous Button */}
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={styles.paginationButton(false, currentPage === 1)}
                    aria-label="Previous Page"
                  >
                    &laquo;
                  </button>
    
                  {/* Page Numbers and Ellipses */}
                  {getPageNumbers().map((item, index) => {
                    if (item === 'left-ellipsis') {
                      return (
                        <button
                          key={`left-ellipsis-${index}`}
                          onClick={() => handlePageChange(1)}
                          style={styles.paginationButton(false, false)}
                          aria-label="First Page"
                        >
                          1
                        </button>
                      );
                    }
    
                    if (item === 'right-ellipsis') {
                      return (
                        <button
                          key={`right-ellipsis-${index}`}
                          onClick={() => handlePageChange(totalPages)}
                          style={styles.paginationButton(false, false)}
                          aria-label="Last Page"
                        >
                          {totalPages}
                        </button>
                      );
                    }
    
                    return (
                      <button
                        key={`page-${item}`}
                        onClick={() => handlePageChange(item)}
                        style={styles.paginationButton(currentPage === item, false)}
                        aria-current={currentPage === item ? 'page' : undefined}
                        aria-label={`Page ${item}`}
                      >
                        {item}
                      </button>
                    );
                  })}
    
                  {/* Next Button */}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={styles.paginationButton(false, currentPage === totalPages)}
                    aria-label="Next Page"
                  >
                    &raquo;
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      );
    };
    
    export default ProjectsTable;