import React, { useState } from "react";
import { Link } from 'react-router-dom'; // Correctly import Link from react-router-dom
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Dropdown from '../parents/Dropdown.js';
import Input from '../parents/Input.js';
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    },
    ErrorText: {
        color: 'red',
        listStyleType: 'disc',
        marginLeft: '20px',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
};

const DJANGO_HOST = config.djangoHost;

const ConnectedIpsModalAddIp = ({ reload }) => {
    const [provider, setSelectedProvider] = useState('');
    const [name, setName] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getToken } = useAuth();

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>You want to add an IP?</label>
        );
    };

    const renderBody = () => {
        const options = {
            'Sendgrid': 'Sendgrid', 
            'Mailgun': 'Mailgun'
        };

        // If loading, show spinner
        if (isLoading) {
            return (
                <div style={defaultStyles.SpinnerContainer}>
                    <Spinner />
                </div>
            );
        }

        // Else, show the form
        return (
            <>
                <p className="text-justify">
                    By adding an IP you are allowing Mission Inbox to connect and receive email sending analytics from it.
                </p>
                <br />
                <br />
                <label className="font-semibold">IP Provider</label>
                <div className="pb-4">
                    <Dropdown 
                        value={provider} 
                        onChange={handleProviderChange}
                        label={"Select IP Provider"} 
                        values={options}
                        disabled={isLoading} // Disable during loading
                    />
                </div>
                <label className="font-semibold">IP Name</label>
                <div className="pb-4">
                    <Input 
                        name={"name"} 
                        placeholder={"IP Name"} 
                        onChange={(e) => setName(e.target.value)} 
                        disabled={isLoading} // Disable during loading
                    />
                </div>
                <label className="font-semibold">API Key</label>
                <div className="pb-4">
                    <Input 
                        name={"key"} 
                        placeholder={"API Key"} 
                        onChange={(e) => setApiKey(e.target.value)} 
                        disabled={isLoading} // Disable during loading
                    />
                </div>
            </>
        );
    };

    const renderOptions = (closeModal) => {
        const action = async () => {
            const newErrors = [];

            // Update regex to allow spaces in IP name
            const nameRegex = /^[a-zA-Z0-9-_— ]+$/; // Allow spaces here
            if (!provider) {
                newErrors.push('Please select a provider.');
            }
            if (!name) {
                newErrors.push('Please enter an IP name.');
            } else if (!nameRegex.test(name)) {
                newErrors.push('IP name can only contain letters, numbers, hyphens (-), underscores (_), em dashes (—), and spaces.');
            }
            if (!apiKey) {
                newErrors.push('Please enter an API key.');
            }

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            setErrors([]);
            setIsLoading(true);

            const url = `${DJANGO_HOST}relay_servers/`;

            try {
                const token = await getToken();
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify({
                        'name': name,
                        'transporter_key': apiKey,
                        'transporter_provider': provider
                    }),
                });

                const data = await response.json();

                if (response.status === 201) {
                    reload();
                    closeModal();
                } else if (response.status === 400) {
                    // Handle validation errors returned by the server
                    const serverErrors = [];
                    for (const key in data) {
                        if (Array.isArray(data[key])) {
                            data[key].forEach(err => serverErrors.push(`${key}: ${err}`));
                        } else {
                            serverErrors.push(`${key}: ${data[key]}`);
                        }
                    }
                    setErrors(serverErrors);
                } else {
                    // Handle other types of errors
                    setErrors(['An unexpected error occurred. Please try again later.']);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrors(['Failed to connect. Please check your network and try again.']);
            }

            setIsLoading(false);
        };

        return (
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel"
                    disabled={isLoading} // Disable during loading
                />
                <Button 
                    action={action} 
                    style={isLoading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label="Yes, Connect"
                    disabled={isLoading}
                />
            </>
        );
    };

    const renderErrors = () => {
        if (errors.length === 0) return null;
        return (
            <ul className="mb-4">
                {errors.map((error, index) => (
                    <li key={index} style={defaultStyles.ErrorText}>
                        {error}
                    </li>
                ))}
            </ul>
        );
    };

    const handleProviderChange = (event) => {
        setSelectedProvider(event.target.value);
    };

    return (
        <Modal
            text={"Add IP"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default ConnectedIpsModalAddIp;
