import React, { useState } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import config from "../../../config/env.js";

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
        marginBottom: '1rem',
    },
    AcceptButton: {
        padding: '0.75rem 1.5rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
};

const styles = {
    modalContent: {
        padding: '1.5rem',
        maxWidth: '600px',
        width: '100%',
        margin: '0 auto',
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        gap: '1.5rem',
    },
    section: {
        flex: 1,
        minWidth: 0, // Allows the section to shrink below its content size
    },
    divider: {
        width: '1px',
        backgroundColor: '#ddd',
        alignSelf: 'stretch',
    },
    sectionTitle: {
        borderBottom: '1px solid #ddd',
        paddingBottom: '0.5rem',
        marginBottom: '1rem',
        fontWeight: 'bold',
        fontSize: '1.1rem',
    },
    field: {
        marginBottom: '0.75rem',
        fontSize: '0.9rem',
        wordBreak: 'break-word',
    },
    fieldTitle: {
        fontWeight: 'bold',
        marginRight: '0.25rem',
    }
};

const MailboxesModalConnect = ({ mailboxData }) => {
    const [errors, setErrors] = useState([]);

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Connect Mailbox</label>
        );
    };

    const renderBody = () => {
        const Username = `${mailboxData.email}`;
        const Password = mailboxData.password;

        return (
            <div style={styles.modalContent}>
                <div style={styles.bodyContainer}>
                    <div style={styles.section}>
                        <h3 style={styles.sectionTitle}>SMTP</h3>
                        {/* <p style={styles.field}><span style={styles.fieldTitle}>Server:</span> mail.inboxment.com</p>  */}
                        <p style={styles.field}><span style={styles.fieldTitle}>Server:</span> {config.mailInABoxHost}</p> 
                        <p style={styles.field}><span style={styles.fieldTitle}>Username:</span> {Username}</p>
                        <p style={styles.field}><span style={styles.fieldTitle}>Password:</span> {Password}</p>
                        <p style={styles.field}><span style={styles.fieldTitle}>Port:</span> 465</p>
                    </div>
                    <div style={styles.divider}></div>
                    <div style={styles.section}>
                        <h3 style={styles.sectionTitle}>IMAP</h3>
                        {/* <p style={styles.field}><span style={styles.fieldTitle}>Server:</span> mail.inboxment.com</p> */}
                        <p style={styles.field}><span style={styles.fieldTitle}>Server:</span> {config.mailInABoxHost}</p> 
                        <p style={styles.field}><span style={styles.fieldTitle}>Username:</span> {Username}</p>
                        <p style={styles.field}><span style={styles.fieldTitle}>Password:</span> {Password}</p>
                        <p style={styles.field}><span style={styles.fieldTitle}>Port:</span> 993</p>
                    </div>
                </div>
            </div>
        );
    };

    const renderOptions = (closeModal) => {
        const accept = () => {
            window.open(`https://${config.mailInABoxHost}/mail/`, '_blank');
            closeModal();  
        };
        
        return (
            <Button action={accept} style={defaultStyles.AcceptButton} label="Enter Mailbox" />
        );
    };

    const renderErrors = () => {
        return (
            <ul style={{ color: 'red', padding: '0 1.5rem', margin: '0.5rem 0' }}>
                {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>
        );
    };
    
    return (
        <Modal
            icon={"connection"}
            tooltip={"Connection"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalConnect;