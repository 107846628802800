import React from 'react';

const styles = {
  ImageContainer: {
    top: '17px',
    left: '68px',
    width: '54px', // Set only width
    aspectRatio: '336 / 337', // Maintain original image aspect ratio
    borderRadius: '8px',
    backgroundPosition: 'center center',
    backgroundSize: 'contain', // Use 'contain' to maintain the aspect ratio
    backgroundRepeat: 'no-repeat',
  },
};

const defaultProps = {
  image: '/thumbnail-5.png',
}

const TopLogo = ({ image }) => {
  const imagePath = image || defaultProps.image;

  return (
    <div 
      style={{
        ...styles.ImageContainer,
        backgroundImage: `url(${process.env.PUBLIC_URL}${imagePath})`,
      }} 
    />
  );
};

export default TopLogo;