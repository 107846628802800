import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '@clerk/clerk-react';

// Custom hook to manage token caching
const useCachedToken = () => {
  const { getToken, isSignedIn } = useAuth(); // Use isSignedIn to check for sign-in status

  // Initialize state with cached token and expiry from localStorage
  const [cachedToken, setCachedToken] = useState(() => {
    const savedToken = localStorage.getItem('token');
    const savedExpiry = localStorage.getItem('tokenExpiry');
    if (savedToken && savedExpiry && new Date(savedExpiry) > new Date()) {
      return { token: savedToken, expiry: new Date(savedExpiry) };
    }
    return null;
  });

  // Function to fetch a new token and update cache
  const fetchToken = useCallback(async () => {
    console.log("Fetching new token");
    try {
      const newToken = await getToken({ template: 'long_lived_token' });
      const decodedToken = JSON.parse(atob(newToken.split('.')[1]));
      const expiry = new Date(decodedToken.exp * 1000);  // Convert expiry to milliseconds

      localStorage.setItem('token', newToken);
      localStorage.setItem('tokenExpiry', expiry.toISOString());

      setCachedToken({ token: newToken, expiry });
    } catch (error) {
      console.error('Error fetching token:', error);
      setCachedToken(null);
    }
  }, [getToken]);

  // Effect to handle sign-in listener
  useEffect(() => {
    const handleSignIn = async () => {
      console.log("User signed in, clearing all cache...");
      // Clear all local storage cache
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiry');
      
      // Set cached token to null and issue a new one
      setCachedToken(null);
      
      await fetchToken(); // Issue new token immediately after sign-in
    };

    if (isSignedIn) {
      handleSignIn(); // If already signed in, handle immediately
    }

    window.addEventListener('signin', handleSignIn);  // Listen for custom sign-in event

    return () => {
      window.removeEventListener('signin', handleSignIn);
      console.log("Removing sign-in event listener...");
    };
  }, [isSignedIn, fetchToken]);

  // Function to check and refresh token
  const checkAndRefreshToken = useCallback(() => {
    if (!cachedToken || cachedToken.expiry <= new Date()) {
      fetchToken();
    }
  }, [cachedToken, fetchToken]);

  // Effect to fetch token if not available or expired
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkAndRefreshToken();
    }, 10000);  // Check every 10 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, [checkAndRefreshToken]);

  // Add a logout listener to clear all cache on sign out
  useEffect(() => {
    const handleLogout = () => {
      console.log("User logged out, clearing all cache.");
      // Clear all local storage cache
      localStorage.clear();
      
      // Set cached token to null
      setCachedToken(null);
    };

    console.log("Adding logout event listener...");
    window.addEventListener('logout', handleLogout);  // Listen for custom logout event

    return () => {
      window.removeEventListener('logout', handleLogout);
      console.log("Removing logout event listener...");
    };
  }, []);

  return cachedToken?.token;
};

export default useCachedToken;
