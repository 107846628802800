import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DomainsTable from './components/Domains/DomainsTable.js';
import DomainsModalAdd from './components/Domains/DomainsModalAdd.js';
import DomainsModalBuy from './components/Domains/DomainsModalBuy.js';
import DomainsCard from './components/Domains/DomainsCard.js';
import { DomainVerificationProvider } from './components/Domains/DomainsVerificationContext';
import { Toaster } from 'react-hot-toast';

const Domains = () => {
  const [cardData, setCardData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  
  const handleReload = useCallback(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  return (
    <>
      <DomainVerificationProvider>
        <div>
          <div>
            <label className='page-header'>Domains</label>
          </div>
          <div className="flex w-full justify-end">
          <Toaster/>
            <DomainsModalAdd reload={handleReload} />
            {/* <DomainsModalBuy /> */}
          <Toaster/>
          </div>
          <div className="px-10 py-5">
            <DomainsTable key={refreshKey} reload={handleReload}/>
          </div>
        </div>
        </DomainVerificationProvider>
        <ToastContainer />
    </>
  );
}

export default Domains;