import React, { useEffect, useState } from 'react';
import AlertsTable from './components/DeliverabilityAlerts/DeliverabilityAlertsTable.js';
import DeliverabilityAlertsCard from './components/DeliverabilityAlerts/DeliverabilityAlertsCard.js';

const DeliverabilityAlerts = () => {
  const [alertsData, setAlertsData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/alerts/', {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => response.json())
      .then(data => setAlertsData(data))
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to load deliverability alerts');
      });
  }, []);

  return (
    <div>
      <div>
        <label className='page-header'>Deliverability Alerts</label>
      </div>
      <div className="mt-12">
        <AlertsTable alerts={alertsData} setError={setError} />
      </div>
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-700">Under Development</h1>
        <p className="mt-2 text-gray-500">We are working hard to bring you this feature.</p>
      </div>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}

export default DeliverabilityAlerts;
