import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import Input from "../parents/Input.js";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import { useUser } from '../../../usercontext';
import axios from 'axios';
import config from '../../../config/env';
import Spinner from '../Spinner'; // Import the Spinner component

const MAX_MAILBOXES = 5; // Define the maximum number of mailboxes allowed

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1rem 2rem', // Reduced padding for better fit
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    DisabledAcceptButton: {
        padding: '1rem 2rem', // Reduced padding for better fit
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed',
    },
    CancelButton: {
        padding: '1rem 2rem', // Reduced padding for better fit
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
        cursor: 'pointer',
    },
    ErrorText: {
        color: 'red',
        listStyleType: 'disc',
        marginLeft: '20px',
        marginBottom: '10px',
    },
    SpinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
    },
};

const DJANGO_HOST = config.djangoHost;

const MailboxesModalAdd = ({ reload }) => {
    const { domain_id } = useParams();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [autoReply, setAutoReply] = useState("");
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [selectedDomainId, setSelectedDomainId] = useState("");
    const [domains, setDomains] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [selectedIpId, setSelectedIpId] = useState("");
    const [amount, setAmount] = useState(1);
    const [domainCart, setDomainCart] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { getToken } = useAuth();
    const { userInfo, isLoading: userLoading, fetchUserInfo } = useUser();
    const [isAuto, setIsAuto] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [replyTo, setReplyTo] = useState(""); 

    // Reset form fields
    const resetForm = (resetDomain = false) => {
        setFirstName("");
        setLastName("");
        setUsername("");
        setPassword("");
        setAutoReply("");
        setAmount(1);
        setDomainCart([]);
        setErrors([]);
        setIsAuto(true);
        setReplyTo("");

        // Only reset domain-related fields if `resetDomain` is true
        if (resetDomain) {
            setSelectedDomain(null);
            setSelectedDomainId("");
            setSelectedProjectId("");
            setSelectedIpId("");
        }
    };

    // Fetch domains on component mount
    useEffect(() => {
        const fetchData = async () => {
            const token = await getToken();
            if (!token) return;

            try {
                // Fetch domains
                const domainResponse = await fetch(`${DJANGO_HOST}domain/`, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                
                const domainData = await domainResponse.json();
                const isDomainDataAnObject = typeof domainData === 'object' && domainData !== null && !Array.isArray(domainData);
                const domainDataResults = isDomainDataAnObject ? domainData.results : domainData;
                setDomains(domainDataResults || []); // Ensure domains is always an array

                const domainsArray = domainDataResults || []; // Access the array of domains

                if (domain_id) {
                    const matchingDomain = domainsArray.find(domain => 
                      domain.id.toString() === domain_id.toString()
                    );                     
                    if (matchingDomain) {
                      setSelectedDomain(matchingDomain);
                      setSelectedDomainId(matchingDomain.id);
                      setSelectedProjectId(matchingDomain.project_id);
                      setSelectedIpId(matchingDomain.relay_server_id);
                    }
                } else if (domainsArray.length > 0) {
                    const firstDomain = domainsArray[0];
                    setSelectedDomain(firstDomain);
                    setSelectedDomainId(firstDomain.id);
                    setSelectedProjectId(firstDomain.project_id);
                    setSelectedIpId(firstDomain.relay_server_id);
                }
                

            } catch (error) {
                console.error('Error fetching data:', error);
                setErrors(prevErrors => [...prevErrors, "Failed to load necessary data"]);
            }

            setIsLoading(false);
        };
        fetchData();
    }, [getToken, DJANGO_HOST, domain_id]);

    // Handle domain selection
    const handleDomainChange = (e) => {
        const domainId = e.target.value;
        setSelectedDomainId(domainId);
        const domainObj = domains.find(domain => domain.id.toString() === domainId.toString());
        if (domainObj) {
            setSelectedDomain(domainObj);
            setSelectedProjectId(domainObj.project_id);
            setSelectedIpId(domainObj.relay_server_id);
        }
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'autoReply':
                setAutoReply(value);
                break;
            case 'amount':
                setAmount(parseInt(value));
                break;
            case 'replyTo':
                setReplyTo(value);
                break;
            default:
                break;
        }
    };

    // Validate form inputs
    const validateForm = () => {
        const newErrors = [];
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Validate first name and last name for both auto and manual
        if (!firstName) newErrors.push("First name is required");
        if (!lastName) newErrors.push("Last name is required");

        // Auto mail validation
        if (isAuto) {
            if (!amount || amount <= 0) newErrors.push("Amount must be greater than 0");
        } else {
            // Manual mail validation (no email format validation here)
            if (!username) {
                newErrors.push("Username is required");
            }
        }

        // Validate replyTo email only if provided (make it optional)
        if (replyTo && !emailRegex.test(replyTo)) {
            newErrors.push("Reply-To email must be a valid email address");
        }

        // Validate password
        if (!password) {
            newErrors.push("Password is required");
        } else if (!validatePassword(password)) {
            newErrors.push("Password must be at least 8 characters long");
        }

        // Validate domain
        if (!selectedDomainId) newErrors.push("Please select a domain");

        // Check mailbox limits
        if (isAuto) {
            if (amount + domainCart.length > MAX_MAILBOXES) {
                newErrors.push(`Adding ${amount} mailboxes exceeds the limit of ${MAX_MAILBOXES}. You can add up to ${MAX_MAILBOXES - domainCart.length} more.`);
            }
        } else {
            if (domainCart.length + 1 > MAX_MAILBOXES) {
                newErrors.push(`Adding this mailbox exceeds the limit of ${MAX_MAILBOXES}. You cannot add more mailboxes.`);
            }
        }

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    // Add mailboxes to the cart with domain display name
    const addToDomainCart = (mailbox, domainName) => {
        setDomainCart(prevCart => {
            const updatedCart = [...prevCart, { ...mailbox, domainDisplayName: domainName }];
            console.log("Updated Mailbox Cart:", updatedCart);
            return updatedCart;
        });
    };

    // Handle form submission to add mailboxes to the cart
    const handleSubmit = () => {
        if (!validateForm()) {
            console.log("Form is invalid", errors);
            return;
        }

        const domainId = selectedDomainId;
        const domainName = selectedDomain ? selectedDomain.name : "";

        if (!domainId) {
            setErrors(prevErrors => [...prevErrors, "Invalid domain selected"]);
            return;
        }

        const passwordToSend = password || "";

        if (isAuto) {
            const emails = generateEmails(firstName, lastName, domainName, amount);
            const totalAfterAdd = domainCart.length + emails.length;

            if (totalAfterAdd > MAX_MAILBOXES) {
                setErrors(prevErrors => [...prevErrors, `Adding ${emails.length} mailboxes exceeds the limit of ${MAX_MAILBOXES}. You can add up to ${MAX_MAILBOXES - domainCart.length} more.`]);
                return;
            }

            emails.forEach(email => {
                const mailbox = {
                    email,
                    firstName,
                    lastName,
                    password: passwordToSend,
                    autoReply,
                    project: selectedProjectId,
                    relay_server: selectedIpId,
                    domain: domainId,
                    reply_to: replyTo, 
                };
                addToDomainCart(mailbox, domainName);  // Pass domainName for display
            });
        } else {
            if (domainCart.length + 1 > MAX_MAILBOXES) {
                setErrors(prevErrors => [...prevErrors, `Adding this mailbox exceeds the limit of ${MAX_MAILBOXES}. You cannot add more mailboxes.`]);
                return;
            }

            const mailbox = {
                email: username,
                firstName,
                lastName,
                password: passwordToSend,
                autoReply,
                project: selectedProjectId,
                relay_server: selectedIpId,
                domain: domainId,
                reply_to: replyTo, 
            };
            addToDomainCart(mailbox, domainName);
        }
    };

    // Submit mailboxes to the backend
    const submitMailboxes = async () => {
        try {
            console.log("Submitting mailboxes...");

            const token = await getToken();  // Await the token retrieval

            if (domainCart.length === 0) {
                setErrors(['No mailboxes to submit.']);
                return;
            }

            // Group mailboxes by shared parameters
            const groups = {};
            for (const mailbox of domainCart) {
                const key = `${mailbox.project}_${mailbox.domain}_${mailbox.relay_server}_${mailbox.firstName}_${mailbox.lastName}_${mailbox.password}`;
                if (!groups[key]) {
                    groups[key] = [];
                }
                groups[key].push(mailbox);
            }

            // Submit each group separately
            for (const groupKey in groups) {
                const groupMailboxes = groups[groupKey];
                const firstMailbox = groupMailboxes[0];

                const emails = groupMailboxes.map(mailbox => mailbox.email);

                const requestData = {
                    emails: emails,
                    project: firstMailbox.project,
                    domain: firstMailbox.domain,
                    relay_server: firstMailbox.relay_server,
                    first_name: firstMailbox.firstName,
                    last_name: firstMailbox.lastName,
                    password: firstMailbox.password,
                    reply_to: firstMailbox.reply_to,
                };

                console.log("Submitting group:", requestData);

                const response = await fetch(`${DJANGO_HOST}mailbox/bulk/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestData),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.error("Error submitting mailboxes:", errorData);

                    // Extract error message from backend response
                    const errorMessage = errorData.error || errorData.detail || "An error occurred while submitting mailboxes.";

                    // Update the errors state to display the error to the user
                    setErrors(prevErrors => [...prevErrors, errorMessage]);

                    // Optionally, you can throw an error to stop further processing
                    throw new Error(errorMessage);
                }

                const data = await response.json();
                console.log("Mailboxes submitted successfully:", data);
            }

            setDomainCart([]);
            await fetchUserInfo();

        } catch (error) {
            console.error("Error submitting mailboxes:", error);
            // Errors are already set in the errors state, so no need to set them here
            throw error; // Rethrow to handle in handlePurchase
        }
    };


    // Handle the purchase process
    const handlePurchase = async (closeModal) => {
        setIsSubmitting(true); // Start loading
        try {
            if (userLoading) {
                alert('User info is not loaded yet.');
                return;
            }

            // Attempt to submit mailboxes to the site
            await submitMailboxes();

            closeModal(); // Close the modal after successful operations
            reload();
        } catch (error) {
            console.error("Error during mailbox creation process:", error);
            // Error messages are already set in the errors state, so no need to set them here
        } finally {
            setIsSubmitting(false); // End loading
            // Refetch user info
            await fetchUserInfo();
            // Reset form after successful submission (already done via onClose)
        }
    };

    // Generate email addresses based on patterns
    const generateEmails = (firstName, lastName, domain, amount) => {
        // Remove spaces from first and last name
        firstName = firstName.replace(/\s+/g, '');
        lastName = lastName.replace(/\s+/g, '');

        const emailPatterns = [
            `${firstName}`,
            `${firstName}.${lastName.charAt(0)}`,
            `${firstName}.${lastName}`,
            `${firstName.charAt(0)}.${lastName}`,
            `${firstName}${lastName.charAt(0)}`,
            `${firstName.charAt(0)}${lastName}`,
        ];

        let emails = [];
        for (let i = 0; i < amount; i++) {
            emails.push(`${emailPatterns[i % emailPatterns.length]}`.toLowerCase());
        }
        return emails;
    };

    // Validate password
    const validatePassword = (password) => {
        return password.length >= 8;
    };

    // Render the modal title
    const renderModalTitle = () => {
        return (
            <label style={defaultStyles.Title}>Add Mailbox</label>
        );
    };

    // Render the modal body
    const renderModalBody = () => {
        const remaining = MAX_MAILBOXES - domainCart.length;

        if (isLoading) {
            return (
                <div style={defaultStyles.SpinnerContainer}>
                    <Spinner />
                </div>
            );
        }

        return (
            <>
                <div className="flex gap-4 mb-4">
                    <div className="flex items-center">
                        <input
                            id="auto-generate"
                            type="radio"
                            name="generationType"
                            checked={isAuto}
                            onChange={() => setIsAuto(true)}
                            className="form-radio"
                            aria-label="Auto Generate Mailboxes"
                        />
                        <label htmlFor="auto-generate" className="ml-2 font-bold">Auto Generate Mailboxes</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            id="manual-create"
                            type="radio"
                            name="generationType"
                            checked={!isAuto}
                            onChange={() => setIsAuto(false)}
                            className="form-radio"
                            aria-label="Manual Mailbox Creation"
                        />
                        <label htmlFor="manual-create" className="ml-2 font-bold">Manual Mailbox Creation</label>
                    </div>
                </div>
                <div className="overflow-visible"> {/* Changed from h-64 overflow-y-auto to overflow-visible */}
                    {isAuto ? (
                        <>
                            <div className="pb-3">
                                <label className="font-semibold">Domain</label>
                                <select
                                    value={selectedDomainId}
                                    onChange={handleDomainChange}
                                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                    disabled={!!domain_id || isSubmitting}  // Disable if domain_id is provided or submitting
                                >
                                    <option value="" disabled>Select a Domain</option>
                                    {Array.isArray(domains) && domains.map(domain => (
                                        <option key={domain.id} value={domain.id}>
                                            {domain.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid grid-cols-2 gap-4 pb-3"> {/* Reduced padding */}
                                <div>
                                    <label className="font-semibold">Sender First Name</label>
                                    <Input name={"firstName"} placeholder={"First Name"} onChange={handleInputChange} disabled={isSubmitting} />
                                </div>
                                <div>
                                    <label className="font-semibold">Sender Last Name</label>
                                    <Input name={"lastName"} placeholder={"Last Name"} onChange={handleInputChange} disabled={isSubmitting} />
                                </div>
                            </div>
                            <div className="pb-3"> {/* Reduced padding */}
                                <label className="font-semibold">How many Mailboxes would you like?</label>
                                <div className="flex items-center mt-2">
                                    <input
                                        name="amount"
                                        type="range"
                                        min="1"
                                        max={remaining > 0 ? Math.min(5, remaining) : 0} // Adjust max based on remaining
                                        value={amount}
                                        onChange={handleInputChange}
                                        className="slider w-full"
                                        disabled={remaining === 0 || isSubmitting}
                                    />
                                    <span className="ml-4">{amount}</span>
                                </div>
                                {remaining === 0 && (
                                    <p className="text-red-500 mt-2">You have reached the maximum limit of {MAX_MAILBOXES} mailboxes.</p>
                                )}
                            </div>
                            <div className="pb-3"> {/* Reduced padding */}
                                <label className="font-semibold">Set the Password (min 8 characters)</label>
                                <Input 
                                    name={"password"} 
                                    type="password"
                                    placeholder={"Set the Password"} 
                                    onChange={handleInputChange} 
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="pb-3">
                                <label className="font-semibold">Reply-To Email</label>
                                <Input 
                                    name={"replyTo"} 
                                    placeholder={"Reply-To Email"} 
                                    onChange={handleInputChange} 
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="flex justify-center">
                                <Button 
                                    action={handleSubmit} 
                                    style={defaultStyles.AcceptButton} 
                                    label="Generate & Add" 
                                    disabled={remaining === 0 || isSubmitting} // Disable if no remaining or submitting
                                />
                            </div>
                            
                        </>
                    ) : (
                        <>
                            <div className="pb-3">
                                <label className="font-semibold">Domain</label>
                                <select
                                    value={selectedDomainId}
                                    onChange={handleDomainChange}
                                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                    disabled={!!domain_id || isSubmitting}  // Disable if domain_id is provided or submitting
                                >
                                    <option value="" disabled>Select a Domain</option>
                                    {domains.map(domain => (
                                        <option key={domain.id} value={domain.id}>
                                            {domain.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="grid grid-cols-2 gap-4 pb-3"> {/* Reduced padding */}
                                <div>
                                    <label className="font-semibold">Sender First Name</label>
                                    <Input name={"firstName"} placeholder={"First Name"} onChange={handleInputChange} disabled={isSubmitting} />
                                </div>
                                <div>
                                    <label className="font-semibold">Sender Last Name</label>
                                    <Input name={"lastName"} placeholder={"Last Name"} onChange={handleInputChange} disabled={isSubmitting} />
                                </div>
                            </div>
                            <div className="pb-3"> {/* Reduced padding */}
                                <label className="font-semibold">Type Username</label>
                                <Input name={"username"} placeholder={"Type Username"} onChange={handleInputChange} disabled={isSubmitting} />
                            </div>
                            <div className="pb-3"> {/* Reduced padding */}
                                <label className="font-semibold">Set the Password</label>
                                <Input name={"password"} type="password" placeholder={"Set the Password"} onChange={handleInputChange} disabled={isSubmitting} />
                            </div>
                            <div className="pb-3">
                                <label className="font-semibold">Reply-To Email</label>
                                <Input 
                                    name={"replyTo"} 
                                    placeholder={"Reply-To Email"} 
                                    onChange={handleInputChange} 
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="flex justify-center">
                                <Button 
                                    action={handleSubmit} 
                                    style={defaultStyles.AcceptButton} 
                                    label="Add" 
                                    disabled={domainCart.length >= MAX_MAILBOXES || isSubmitting} // Disable if limit reached or submitting
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="py-4">
                    <h3 className="font-bold">Mailbox Cart ({domainCart.length}/{MAX_MAILBOXES})</h3>
                    {domainCart.length === 0 ? (
                        isSubmitting ? (
                            <div style={defaultStyles.SpinnerContainer}>
                                <Spinner />
                            </div>
                        ) : (
                            <p>No mailboxes added yet.</p>
                        )
                    ) : (
                        <ul>
                            {domainCart.map((mailbox, index) => {
                                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                                // Use domainDisplayName for rendering
                                const emailDisplay = `${mailbox.email}@${mailbox.domainDisplayName}`;

                                if (!emailRegex.test(emailDisplay)) {
                                    return (
                                        <li key={index} style={{ color: 'red' }}>
                                            Invalid email format: {emailDisplay}
                                        </li>
                                    );
                                }

                                return <li key={index}>{emailDisplay}</li>;
                            })}
                        </ul>
                    )}
                </div>
            </>
            );
        };
        
    // Render the modal options (buttons)
    const renderModalOptions = (closeModal) => {
        return (
            <div className="flex justify-end gap-2"> {/* Added gap for spacing */}
                <Button 
                    action={() => { 
                        resetForm();  // Do not reset domain-related fields
                        closeModal(); 
                    }} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel" 
                    disabled={isSubmitting} // Disable when submitting
                />
                <Button 
                    action={() => handlePurchase(closeModal)} 
                    style={isSubmitting ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={isSubmitting ? "Processing..." : "Submit"} 
                    disabled={isSubmitting || domainCart.length === 0} // Disable when submitting or cart is empty
                />
            </div>
        );
    };

    // Render any form errors
    const renderModalErrors = () => {
        if (errors.length === 0) return null;
        return (
            <ul className="mb-4">
                {errors.map((error, index) => (
                    <li key={index} style={defaultStyles.ErrorText}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <>  
            {/* Modal */}
            <Modal
                text={"Add Mailbox"}
                renderTitle={renderModalTitle}
                renderBody={renderModalBody}
                renderOptions={renderModalOptions}
                renderErrors={renderModalErrors}
                onClose={() => {
                    resetForm();
                }}
            />
        </>
    );
};

export default MailboxesModalAdd;
