import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner.js';
import Dropwdown from "../parents/Dropdown.js"
import ReactECharts from 'echarts-for-react';

function ConnectedIpsChart() {
	const [chartData, setChartData] = useState([]);
	const [selectedPeriod, setSelectedPeriod] = useState('Weekly');

	const options = {
	    color: ['#37A2FF', '#00DDFF', '#80FFA5', '#FFBF00', '#FF0087'],
	    meta : ["Compaints", "Bounce Rate", "Open Rate", "Sent"],
	    title: {
	      text: 'Historical Data'
	    },
	    tooltip: {
	      trigger: 'axis',
	      axisPointer: {
	        type: 'cross',
	        label: {
	          backgroundColor: '#6a7985'
	        }
	      }
	    },
	    legend: {
	      data: ['Sent', 'Open Rate', 'Bounce Rate', 'Complaints']
	    //   dat: ['Sent', 'Delivered', 'Open Rate', 'Bounce Rate', 'Complaints']
	    },
	    toolbox: {
	      feature: {
	        saveAsImage: {}
	      }
	    },
	    grid: {
	      left: '3%',
	      right: '4%',
	      bottom: '3%',
	      containLabel: true
	    },
	    xAxis: [
	      {
	        type: 'category',
	        boundaryGap: false,
	        data: chartData.map(item => item.date)
	      }
	    ],
	    yAxis: [
	      {
	        type: 'value'
	      }
	    ],
	    series: [
	      {
	        name: 'Sent',
	        type: 'line',
	        stack: 'Total',
	        areaStyle: {},
	        emphasis: {
	          focus: 'series'
	        },
	        data: chartData.map(item => item.sent)
	      },
	    //   {
	    //     name: 'Delivered',
	    //     type: 'line',
	    //     stack: 'Total',
	    //     areaStyle: {},
	    //     emphasis: {
	    //       focus: 'series'
	    //     },
	    //     data: chartData.map(item => item.delivered)
	    //   },
	      {
	        name: 'Open Rate',
	        type: 'line',
	        stack: 'Total',
	        areaStyle: {},
	        emphasis: {
	          focus: 'series'
	        },
	        data: chartData.map(item => item.open)
	      },
	      {
	        name: 'Bounce Rate',
	        type: 'line',
	        stack: 'Total',
	        areaStyle: {},
	        emphasis: {
	          focus: 'series'
	        },
	        data: chartData.map(item => item.bounces)
	      },
	      {
	        name: 'Complaints',
	        type: 'line',
	        stack: 'Total',
	        areaStyle: {},
	        emphasis: {
	          focus: 'series'
	        },
	        data: chartData.map(item => item.compaints)
	      } 
	    ]
	  };

	useEffect(() => {
	    // Fetch data from the API
	    const url = selectedPeriod === 'Weekly'
	        ? 'https://test.inboxment.com/test/week/'
	        : 'https://test.inboxment.com/test/twoweek/';

	    fetch(url, {
	        headers: {
	            'X-Requested-With': 'XMLHttpRequest'
	        }
	    })
	    .then(response => response.json())
	    .then(data => setChartData(data))
	    .catch(error => console.error('Error fetching data:', error));
	}, [selectedPeriod]);

	const handlePeriodChange = (event) => {
	    setSelectedPeriod(event.target.value);
	};

	if (chartData.length === 0) {
		return <Spinner/>;
	}

	return (
		<div>
			<div className="flex w-full justify-end">
			  <Dropwdown value={selectedPeriod} onChange={handlePeriodChange} label={selectedPeriod} values={['Weekly', 'Monthly']}/>
			</div>
		  <ReactECharts option={options} />
		</div>
	);
}

export default ConnectedIpsChart;