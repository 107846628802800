import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import Table from '../parents/Table.js';
import DomainsModalDNS from './DomainsModalDNS.js';
import DomainsModalDelete from './DomainsModalDelete.js';
import DomainsModalDisable from './DomainsModalDisable.js';
import useCachedToken from '../../../useCachedToken';
import { useDomainVerification } from './DomainsVerificationContext';
import config from '../../../config/env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons'; // Using a down arrow icon
import Spinner from '../Spinner'; // Import the Spinner component

const DJANGO_HOST = config.djangoHost;

const DomainsTable = ({ reload }) => {
    const { relay_server_id, project_id } = useParams();
    const [domains, setDomains] = useState([]); // Domains to display on the current page
    const [totalItems, setTotalItems] = useState(0); // Total number of domains
    const token = useCachedToken(); // Get the token using the custom hook
    const { verifyingDomains, domainStatuses } = useDomainVerification();

    // Sorting State
    const [sortOption, setSortOption] = useState(null); // Initially no sort selected
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown visibility
    const dropdownRef = useRef(null); // Reference for click outside

    // Pagination States
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [itemsPerPage, setItemsPerPage] = useState(20); // Items per page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    // Loading and Error States
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    // Determine sortBy and sortOrder based on sortOption
    const getSortParameters = (option) => {
        switch (option) {
            case 'Name A-Z':
                return { sortBy: 'name', sortOrder: 'asc' };
            case 'Name Z-A':
                return { sortBy: 'name', sortOrder: 'desc' };
            case 'Latest':
                return { sortBy: 'created_at', sortOrder: 'desc' };
            case 'Oldest':
                return { sortBy: 'created_at', sortOrder: 'asc' };
            default:
                return { sortBy: 'created_at', sortOrder: 'desc' }; // Default sort
        }
    };

    const { sortBy, sortOrder } = getSortParameters(sortOption);

    // Construct the API URL based on sort parameters and URL params
    let url = `${DJANGO_HOST}domain/?sort_by=${sortBy}&sort_order=${sortOrder}`;

    if (relay_server_id) {
        url += `&relay_server_id=${relay_server_id}`;
    }

    if (project_id) {
        url += `&project_id=${project_id}`;
    }

    const headers = [
        { name: "Domain", available: true },
        { name: "Mailboxes", available: true },
        { name: "Sent Emails", available: true },
        { name: "Status", available: true }
        // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints, health
    ];

    // Fetch domains from the API
    const fetchDomains = async () => {
        setIsLoading(true);
        setErrors([]);
        try {
            const limit = itemsPerPage;
            const offset = (currentPage - 1) * itemsPerPage;
            const fetchUrl = `${url}&limit=${limit}&offset=${offset}`;

            const response = await fetch(fetchUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            setTotalItems(data.count || 0);
            setDomains(Array.isArray(data.results) ? data.results : []);
        } catch (error) {
            console.error('Error fetching domains:', error);
            setDomains([]); // Reset to empty array on error
            setTotalItems(0);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch domains when component mounts or dependencies change
    useEffect(() => {
        if (token) {
            fetchDomains();
        }
    }, [token, sortOption, relay_server_id, project_id, currentPage, itemsPerPage]); // Include currentPage and itemsPerPage as dependencies

    // Calculate totalPages whenever totalItems or itemsPerPage changes
    useEffect(() => {
        const total = Math.ceil(totalItems / itemsPerPage) || 1;
        setTotalPages(total);

        // Ensure currentPage is within totalPages
        if (currentPage > total) {
            setCurrentPage(total);
        }
    }, [totalItems, itemsPerPage]);

    // Handle sort option selection
    const handleSortOptionSelect = (option) => {
        setSortOption(option);
        setIsDropdownOpen(false); // Close dropdown after selection
        setCurrentPage(1); // Reset to first page on sort
    };

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    // Custom renderers for specific columns
    const renderDomainLink = (cell, row) => {
        const domainId = row.id;
        return (
            <td className="py-3 px-6 whitespace-normal break-words">
                <Link to={`/navigate/domain/${domainId}/`}>
                    {cell}
                </Link>
            </td>
        );
    };

    const renderStatus = (cell, row) => {
        // Use the status field from the row directly, assuming it's a boolean
        const isVerified = row.status; // status is a boolean (true or false)
        if (verifyingDomains[row.id]) {
            return (
                <td className="py-3 px-6">
                    <span className="text-yellow-500">Verifying<span className="animate-pulse">...</span></span>
                </td>
            );
        }
        return (
            <td className="py-3 px-6">
                {isVerified ? 
                    <span className="text-green-500">Verified</span> : 
                    <span className="text-red-500">Not Verified</span>
                }
            </td>
        );
    };
    const renderPrefix = (row) => {
        const isChecked = row.active === "Active" ? true : false;
        return (
            <td className="py-3 px-6 flex">
                <DomainsModalDisable isChecked={isChecked} />
            </td>
        );
    };

    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex whitespace-normal break-words">
                <DomainsModalDNS reload={reload} name={row.name} id={row.id} />
                <DomainsModalDelete reload={reload} name={row.name} id={row.id} />
            </td>
        );
    };

    // Process domains to remove redundant fields if necessary
    const processedDomains = domains.map(({ project_slug, relay_server_slug, ...rest }) => rest);

    // Pagination Helper Function
    const getPageNumbers = () => {
        const pages = [];

        if (totalPages <= 3) {
            // If there are 3 or fewer pages, show all page numbers
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Determine when to show ellipses and which page numbers to display
            if (currentPage > 2) {
                pages.push('left-ellipsis');
            }

            // Always show currentPage and its immediate neighbors
            const startPage = Math.max(1, currentPage - 1);
            const endPage = Math.min(totalPages, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (currentPage < totalPages - 1) {
                pages.push('right-ellipsis');
            }
        }

        return pages;
    };

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber === 'left-ellipsis') {
            setCurrentPage(1);
            return;
        }
        if (pageNumber === 'right-ellipsis') {
            setCurrentPage(totalPages);
            return;
        }
        setCurrentPage(pageNumber);
    };

    // Handle itemsPerPage change
    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1); // Reset to first page on itemsPerPage change
    };

    // Style Objects
    const styles = {
        headerButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
            position: 'relative', // To position dropdown absolutely
        },
        itemsPerPageContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
        },
        sortDropdownButton: {
            backgroundColor: '#17a2b8',
            color: 'white',
            padding: '8px 12px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            whiteSpace: 'nowrap', // Prevent text wrapping
        },
        dropdownMenu: {
            position: 'absolute',
            top: '100%',
            right: '0',
            backgroundColor: 'white',
            listStyle: 'none',
            padding: '0',
            margin: '5px 0 0 0',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1000,
            minWidth: '150px',
        },
        dropdownItem: (isSelected) => ({
            padding: '10px 15px',
            cursor: 'pointer',
            backgroundColor: isSelected ? '#e0f7fa' : 'white',
            transition: 'background-color 0.2s',
        }),
        showLabel: {
            marginRight: '10px',
        },
        itemsPerPageSelect: {
            padding: '6px 10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            cursor: 'pointer',
            width: '100px',
        },
        paginationContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            gap: '5px',
        },
        paginationButton: (isActive, isDisabled) => ({
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            backgroundColor: isActive ? '#17a2b8' : 'white',
            color: isActive ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.6 : 1,
        }),
        paginationEllipsis: {
            padding: '8px 12px',
        },
        spinnerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 0',
        },
        errorContainer: {
            color: 'red',
            marginBottom: '10px',
            textAlign: 'center',
        },
    };

    return (
        <div>
          <div style={styles.headerButtons}>
            {/* Sort and Items Per Page Controls */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {/* Sort By Dropdown Button */}
              <div style={{ position: 'relative' }} ref={dropdownRef}>
                <button
                  onClick={() => toggleDropdown(!isDropdownOpen)}
                  style={styles.sortDropdownButton}
                  aria-haspopup="listbox"
                  aria-expanded={isDropdownOpen}
                >
                  {/* Button Label */}
                  <span>{sortOption ? sortOption : 'Sort by'}</span>
                  {/* Down Arrow Icon */}
                  <FontAwesomeIcon icon={faSortDown} />
                </button>
    
                {/* Dropdown Menu */}
                {isDropdownOpen && (
                  <ul
                    role="listbox"
                    style={styles.dropdownMenu}
                  >
                    {['Name A-Z', 'Name Z-A', 'Latest', 'Oldest'].map(option => (
                      <li
                        key={option}
                        onClick={() => {
                          handleSortOptionSelect(option);
                          toggleDropdown(false);
                        }}
                        role="option"
                        aria-selected={sortOption === option}
                        style={styles.dropdownItem(sortOption === option)}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = sortOption === option ? '#e0f7fa' : 'white'}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
    
              {/* Items Per Page Selector */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="itemsPerPage" style={{ marginRight: '10px' }}>Show:</label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                  style={styles.itemsPerPageSelect}
                  disabled={isLoading} // Disable during loading
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
    
          {/* Error Messages */}
          {errors.length > 0 && (
            <div style={styles.errorContainer}>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
    
          {/* Loading Spinner */}
          {isLoading ? (
            <div style={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <>
              {/* Table Component */}
              <Table
                title={"Domains"}
                headers={headers}
                data={processedDomains} // Pass the current page's domains
                preffixColumn={null}
                suffixColumn={"Actions"}
                renderPrefix={renderPrefix}
                renderSuffix={renderSuffix}
                customRenderers={{ 
                  "name": renderDomainLink,
                  "status": renderStatus
                }}
                excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints', 'health', 'project_id', 'relay_server_id']}  // Exclude unwanted fields
              />
    
              {/* Pagination Controls */}
              {totalPages > 1 && (
                <div style={styles.paginationContainer}>
                  {/* Previous Button */}
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={styles.paginationButton(false, currentPage === 1)}
                    aria-label="Previous Page"
                  >
                    &laquo;
                  </button>
    
                  {/* Page Numbers and Ellipses */}
                  {getPageNumbers().map((item, index) => {
                    if (item === 'left-ellipsis') {
                      return (
                        <button
                          key={`left-ellipsis-${index}`}
                          onClick={() => handlePageChange(1)}
                          style={styles.paginationButton(false, false)}
                          aria-label="First Page"
                        >
                          1
                        </button>
                      );
                    }
    
                    if (item === 'right-ellipsis') {
                      return (
                        <button
                          key={`right-ellipsis-${index}`}
                          onClick={() => handlePageChange(totalPages)}
                          style={styles.paginationButton(false, false)}
                          aria-label="Last Page"
                        >
                          {totalPages}
                        </button>
                      );
                    }
    
                    return (
                      <button
                        key={`page-${item}`}
                        onClick={() => handlePageChange(item)}
                        style={styles.paginationButton(currentPage === item, false)}
                        aria-current={currentPage === item ? 'page' : undefined}
                        aria-label={`Page ${item}`}
                      >
                        {item}
                      </button>
                    );
                  })}
    
                  {/* Next Button */}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={styles.paginationButton(false, currentPage === totalPages)}
                    aria-label="Next Page"
                  >
                    &raquo;
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      );
    };
    
    export default DomainsTable;