import React, {useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';

import ProjectsCard from './components/Projects/ProjectsCard.js';
import ProjectsTable from './components/Projects/ProjectsTable.js';
import ProjectsModalAdd from './components/Projects/ProjectsModalAdd.js';

const  Projects = () => {
  const [cardData, setCardData] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);

  const triggerReload = useCallback(() => {
      setReloadKey((prevKey) => prevKey + 1);
  }, []);

  
  return (
    <div key={reloadKey}>
      <label className='page-header'>Projects</label>
      <div className="flex w-full justify-end">
        <ProjectsModalAdd reload={triggerReload}></ProjectsModalAdd>
      </div>
      <div className="px-10 py-5">
        <ProjectsTable reload={triggerReload}></ProjectsTable>
      </div>
    </div>
  );
}

export default Projects;